import React, { useCallback, useEffect, useLayoutEffect, useMemo, useReducer, useRef, useState } from 'react'
import styled from 'styled-components'
import { has } from 'lodash'
import BigNumber from 'bignumber.js'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { useCookies } from 'react-cookie'
import { BsFillPlusCircleFill } from 'react-icons/bs'
import { Dropdown } from '@nextui-org/react'
import { Flex, Grid, FileButton } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom'
import { notify, useNotifications } from 'reapop'
import { useTranslation } from 'contexts/I18nContext'
import PageLoader from 'components/Loader/PageLoader'
import { StyledText, StyledButton, ListContainer, StyledMText } from 'components/GlobalStyle'
import Pagination from 'components/Pagination/Pagination'
import { ListingStateObjType, ListingMobileStateObjType, ListingCheckInStateObjType, userDetailObjType, certDownloadInfoType, campaignClaimInfoType, certCategory } from 'config/constants/types'
import { useTokenAuth } from 'hooks/useTokenAuth'
import { kycStatus } from 'views/AlumniKyc/constant'
import { useUserCertListing, useUserCertListingMobileVersion, useUserMasdidCheckInLogListing, useGetUserDetails, useCheckAuth, useCheckKyc, useSetCertPrivacy, useUploadProfilePic, useRequestJwt, useGetCertCategories } from './hooks/useUserCertListing'
import { useCheckCertDownload, useCheckout, useCheckPaymentGateway, useFreeCheckout, useCheckPersistCertDownload } from './hooks/usePayment'
import { useCheckCampaignClaim, useCampaignClaim } from './hooks/useCampaignClaim'
import { useCheckWalletBalance } from './hooks/useWithdrawal'
import PaymentModal from './components/PaymentModal'
import RewardModal from './components/RewardModal'
import EmailModal from './components/EmailModal'
import WithdrawModal from './components/WithdrawModal'
import WithdrawListModal from './components/WithdrawListModal'
import ProfileImgReminderModal from './components/ProfileImgReminderModal'
import { paymentGatewayStatusByCertType, paymentGatewayStatusType } from './constant'
import CertItem from './components/CertItem'


const BannerSection = styled.section({
  position: "relative",
  overflow: "visible",
  minHeight: "260px",
  backgroundImage: "url('/images/banner.png')",
  backgroundPosition: "center center",
  // paddingTop: "110px"
})
const BannerContainer = styled(Grid)`
  // max-width: 1100px;
  // margin-bottom: 50px;

  @media only screen and (min-width: 350px) and (max-width: 991px) {
    width: 100%;
    min-width: auto;
    // padding: 0 30px;
  }
  @media only screen and (max-width: 350px) {
    width: 100%;
    min-width: auto;
    padding: 0 20px;
  }
`
const StyledProfileFlex = styled(Flex)`
  @media only screen and (max-width: 990px) {
    align-items: center;
  }
`
const StyledProfileInnerFlex = styled(Flex)`
  @media only screen and (max-width: 990px) {
    justify-content: center;
    flex-direction: column;
    margin-bottom: 5px;
  }
`
const StyledUploadWrapper = styled(Flex)`
  transition: .2s transform ease-out;
  // opacity: 0;

  // &:hover {
  //   opacity: 1;
  //   will-change: transform;
  // }

  // &:hover::after {
  //   content: '';
  //   background: rgba(255, 255, 255, 0.4);
  //   width: 100%;
  //   height: 100%;
  //   position: absolute;
  //   top: 0;
  //   z-index: -1;
  //   border-radius: 50%;
  // }

  // @media only screen and (max-width: 990px) {
    opacity: 1;
    width: 100%;
    align-items: end;
    justify-content: end;

    &:hover::after {
      background: unset;
    }
  // }
`
const StyledUploadLabel = styled(StyledMText)`
  font-size: 13px;

  // @media only screen and (max-width: 990px) {
    display: none;
  // }
`

const Profile: React.FC = () => {
  const intl = useIntl()
  const { userId } = useParams<{ userId: string }>()
  const [cookie, setCookie, removeCookie] = useCookies(["_acc"])
  const { search } = useLocation()
  const navigate = useNavigate()
  const query = useMemo(() => new URLSearchParams(search), [search])  // success, ref_id

  const [inAction, setInAction] = useState(false)
  const initStateData: ListingStateObjType = useMemo(() => ({
    loading: true, latestListing: [], listCurrentPage: 1, listMaxPage: 0, listMaxPerPage: 9, totalItem: 0
  }), [])
  const [stateObj, setStateObj] = useReducer((state: ListingStateObjType, newState: Partial<ListingStateObjType>) => ({
    ...state, ...newState
  }), initStateData)
  const initMobileStateData: ListingMobileStateObjType = useMemo(() => ({
    loading: true, latestListing: []
  }), [])
  const [MobilestateObj, setMobileStateObj] = useReducer((state: ListingMobileStateObjType, newState: Partial<ListingMobileStateObjType>) => ({
    ...state, ...newState
  }), initMobileStateData)
  const initCheckInStateData: ListingCheckInStateObjType = useMemo(() => ({
    loading: true, latestCheckInListing: [], listCurrentPage: 1, listMaxPage: 0, listMaxPerPage: 10, totalItem: 0
  }), [])
  const [CheckInStateObj, setCheckInStateObj] = useReducer((state: ListingCheckInStateObjType, newState: Partial<ListingCheckInStateObjType>) => ({
    ...state, ...newState
  }), initCheckInStateData)
  const initUserDetails = useMemo(() => ({ user_id: null, full_name: '', email: '', profile_picture: null, wallet_address: '' }), [])
  const [userDetails, setUserDetails] = useState<userDetailObjType>(initUserDetails)
  const [authDetails, setAuthDetails] = useState<userDetailObjType>(initUserDetails)
  const userDetailsRef = useRef(userDetails)
  const authDetailsRef = useRef(authDetails)
  const [extTokenValidity, setExtTokenValidity] = useState(false)
  const initCertDownloadInfo: certDownloadInfoType = useMemo(() => ({ link: "", cert_id: null }), [])
  const [certDownloadInfo, setCertDownloadInfo] = useState<certDownloadInfoType>(initCertDownloadInfo)
  const [certDownloadErrMsg, setCertDownloadErrMsg] = useState("")
  const [isModalOpened, setIsModalOpen] = useState(false)
  const [downloadCheckLoading, setDownloadCheckLoading] = useState(true)
  const initCampaignClaimInfo = useMemo<campaignClaimInfoType>(() => ({
    claimable_certificate_ids: [],
    total_claimable: 0,
    campaign: {
      name: "",
      issued_by_masverse_only: false,
      limit_per_email: false,
      cert_reusable: false
    }
  }), [])
  const [campaignClaimInfo, setCampaignClaimInfo] = useState<campaignClaimInfoType>(initCampaignClaimInfo)
  const [campaignClaimAmount, setCampaignClaimAmount] = useState("0")
  const [paymentGatewayStatus, setPaymentGatewayStatus] = useState(paymentGatewayStatusType.OFF)
  const [userKycStatus, setUserKycStatus] = useState(kycStatus.PENDING)
  const [rewardAmount, setRewardAmount] = useState(new BigNumber("0"))
  const [minClaimAmount, setMinClaimAmount] = useState<BigNumber | null>(null)
  const [selectedClaimCertId, setSelectedClaimCertId] = useState<number | null>(null)
  const initialCertCategoryList = useMemo<certCategory[]>(() => [{ category_name: '', color_code: '' }], [])
  const [certCategoriesList, setCertCategoriesList] = useState<certCategory[]>(initialCertCategoryList)
  const [selectedCertCategory, setSelectedCategory] = useState(new Set(['']))
  const selectedCertCategoryValue = useMemo(() => Array.from(selectedCertCategory).join(', '), [selectedCertCategory])

  const { tokenValidity, content } = useTokenAuth()
  const { onGetUserDetails: handleGetUserDetails } = useGetUserDetails()
  const { onCheckAuth: handleCheckAuth } = useCheckAuth()
  const { onUserCertListing: handleUserCertListing } = useUserCertListing()
  const { onuseUserCertListingMobileVersion: handleUserCertListingMobileVersion } = useUserCertListingMobileVersion()
  const { onUserCheckInLogListing: handleUserCheckInLogListing } = useUserMasdidCheckInLogListing()
  const { onCheckout: handleCheckout } = useCheckout()
  const { onCheckCertDownload: handleCheckCertDownload } = useCheckCertDownload()
  const { onCheckCampaignClaim: handleCheckCampaignClaim } = useCheckCampaignClaim()
  const { onCampaignClaim: handleCampaignClaim } = useCampaignClaim()
  const { onCheckPaymentGateway: handleCheckPaymentGateway } = useCheckPaymentGateway()
  const { onFreeCheckout: handleFreeCheckout } = useFreeCheckout()
  const { onCheckKyc: handleCheckKyc } = useCheckKyc()
  const { onCheckWalletBalance: handleCheckWalletBalance } = useCheckWalletBalance()
  const { onCheckPersistCertDownload: handleCheckPersistCertDownload } = useCheckPersistCertDownload()
  const { onSetCertPrivacy: handleSetCertPrivacy } = useSetCertPrivacy()
  const { onUploadProfilePic: handleUploadProfilePic } = useUploadProfilePic()
  const { onRequestJwt: handleRequestJwt } = useRequestJwt()
  const { onGetCertCategories: handleGetCertCategories } = useGetCertCategories()
  const [dataSource, setDataSource] = useState(Array.from({ length: 20 }));
  const [hasMore, setHasMore] = useState(true);
  const [copySuccess, setCopySuccess] = useState('');

  const [paymentOpened, { open: openPayment, close: closePayment }] = useDisclosure(false, {
    onOpen: () => setInAction(true), onClose: () => setInAction(false)
  })
  const [emailOpened, { open: openEmail, close: closeEmail }] = useDisclosure(false, {
    onOpen: () => setInAction(true), onClose: () => setInAction(false)
  })

  const [rewardOpened, { open: openReward, close: closeReward }] = useDisclosure(false, {
    onClose: () => setInAction(false)
  })
  const [withdrawOpened, { open: openWithdraw, close: closeWithdraw }] = useDisclosure(false, {
    onClose: async () => { await checkWalletBalance(); setInAction(false) }
  })
  const [withdrawListingOpened, { open: openWithdrawListing, close: closeWithdrawListing }] = useDisclosure(false, {
    onClose: () => setInAction(false)
  })
  const [ProfileImgReminderOpened, { open: openProfileImgReminder, close: closeProfileImgReminder }] = useDisclosure(false)

  // handle scroll to view with offset
  const scrollIntoViewWithOffset = (selector: string, offset: number) => {
    window.scrollTo({
      behavior: 'smooth',
      top:
        document.getElementById(selector).getBoundingClientRect().top -
        document.body.getBoundingClientRect().top - offset
    })
  }

  const loginValidity = useCallback(() => {
    return tokenValidity && extTokenValidity
  }, [tokenValidity, extTokenValidity])

  // when login is valid and without user id param or with user id param identical to the current user
  const displayOwnProfile = useCallback(() => {
    return loginValidity() && (!userId || (userId && userId === authDetails.user_id.toString()))
  }, [authDetails.user_id, loginValidity, userId])

  // handle checkout
  const checkoutPrint = async (certId) => {
    try {
      if (!content) {
        throw new Error("credential error")
      }
      const response = await handleCheckout(certId, content.token)
      if (response.error || response.message !== "OK") throw new Error(response.message)
      window.location.replace(response.data.url)
    } catch (e: any) {
      const { message = "" } = e
      if (message.includes("Invalid Request")) {
        notify(intl.formatMessage({
          id: 'toast-error-msg-req-failed',
          defaultMessage: 'Request failed.'
        }), 'error', {
          title: intl.formatMessage({
            id: 'toast-error-title',
            defaultMessage: 'Error'
          })
        })
      } else {
        notify(intl.formatMessage({
          id: 'toast-error-msg-general',
          defaultMessage: 'Please try again. Message: {message}',
        }, { message }), 'error', {
          title: intl.formatMessage({
            id: 'toast-error-title',
            defaultMessage: 'Error'
          })
        })
      }
    }
  }

  // handle claim reward
  const onClaimReward = async () => {
    setInAction(true)
    try {
      const response = await handleCampaignClaim(selectedClaimCertId, has(content, "token") ? content.token : null)
      if (response.error) throw new Error(response.message)
      setCampaignClaimAmount(response.data.amount)
      await checkWalletBalance()
      await checkCampaignClaim()
    } catch (e: any) {
      const { message = "" } = e
      if (message.includes("Invalid Request")) {
        notify(intl.formatMessage({
          id: 'toast-error-msg-req-failed',
          defaultMessage: 'Request failed.'
        }), 'error', {
          title: intl.formatMessage({
            id: 'toast-error-title',
            defaultMessage: 'Error'
          }),
        })
      } else {
        notify(intl.formatMessage({
          id: 'toast-error-msg-general',
          defaultMessage: 'Please try again. Message: {message}',
        }, { message }), 'error', {
          title: intl.formatMessage({
            id: 'toast-error-title',
            defaultMessage: 'Error'
          })
        })
      }
      setCampaignClaimAmount("0")
    }
    setSelectedClaimCertId(null)
    setInAction(false)
  }

  // handle free download
  const onFreeCheckout = async (_certId) => {
    setInAction(true)
    try {
      const response = await handleFreeCheckout(_certId, has(content, "token") ? content.token : null)
      if (response.error) throw new Error(response.message)
      window.location.replace(response.data.link)
    } catch (e: any) {
      const { message = "" } = e
      if (message.includes("Invalid Request")) {
        notify(intl.formatMessage({
          id: 'toast-error-msg-req-failed',
          defaultMessage: 'Request failed.'
        }), 'error', {
          title: intl.formatMessage({
            id: 'toast-error-title',
            defaultMessage: 'Error'
          })
        })
      } else {
        notify(intl.formatMessage({
          id: 'toast-error-msg-general',
          defaultMessage: 'Please try again. Message: {message}',
        }, { message }), 'error', {
          title: intl.formatMessage({
            id: 'toast-error-title',
            defaultMessage: 'Error'
          })
        })
      }
    }
    setInAction(false)
  }

  // handle persist paid download
  const onPaidDownload = async (_link) => {
    setInAction(true)
    try {
      const response = await handleCheckPersistCertDownload(_link, has(content, "token") ? content.token : null)
      if (response.error) throw new Error(response.message)
      window.location.replace(response.data.link)
    } catch (e: any) {
      const { message = "" } = e
      if (message.includes("Invalid Request")) {
        notify(intl.formatMessage({
          id: 'toast-error-msg-req-failed',
          defaultMessage: 'Request failed.'
        }), 'error', {
          title: intl.formatMessage({
            id: 'toast-error-title',
            defaultMessage: 'Error'
          })
        })
      } else {
        notify(intl.formatMessage({
          id: 'toast-error-msg-general',
          defaultMessage: 'Please try again. Message: {message}',
        }, { message }), 'error', {
          title: intl.formatMessage({
            id: 'toast-error-title',
            defaultMessage: 'Error'
          })
        })
      }
    }
    setInAction(false)
  }

  // check user wallet balance (accumulated reward)
  const checkWalletBalance = useCallback(async () => {
    try {
      if (content && content.token) {
        const response = await handleCheckWalletBalance(content.token)
        if (response.error) throw new Error(response.message)
        setRewardAmount(new BigNumber(response.data.amount))
        setMinClaimAmount(new BigNumber(response.data.minimum_claim))
      } else {
        setRewardAmount(new BigNumber("0"))
        setMinClaimAmount(null)
      }
    } catch (e: any) {
      setRewardAmount(new BigNumber("0"))
      setMinClaimAmount(null)
    }
  }, [handleCheckWalletBalance, content])

  // check payment gateway status / free checkout
  const checkPaymentGatewayStatus = useCallback(async () => {
    try {
      if (content && content.token) {
        const response = await handleCheckPaymentGateway(content.token)
        if (response.error) throw new Error(response.message)
        setPaymentGatewayStatus(response.data.status)
      } else {
        setPaymentGatewayStatus(paymentGatewayStatusType.OFF)
      }
    } catch (e: any) {
      setPaymentGatewayStatus(paymentGatewayStatusType.OFF)
    }
  }, [handleCheckPaymentGateway, content])

  // check ref id from payment
  const checkPayment = useCallback(async () => {
    try {
      if (query.get('ref_id')) {
        const response = await handleCheckCertDownload(query.get('ref_id'))
        if (response.error) throw new Error(response.message)
        setCertDownloadInfo(response.data)
        setCertDownloadErrMsg("")
        return response.data
        // eslint-disable-next-line no-else-return
      } else {
        setCertDownloadInfo(initCertDownloadInfo)
        setCertDownloadErrMsg("")
      }
    } catch (e: any) {
      setCertDownloadInfo(initCertDownloadInfo)
      const { message = "" } = e
      if (message.includes("Download link is expired")) {
        setCertDownloadErrMsg(intl.formatMessage({ id: 'profile-error-msg1', defaultMessage: "Download link has expired." }))
      }
    }
    return initCertDownloadInfo
  }, [handleCheckCertDownload, query, intl, initCertDownloadInfo])

  const toRef = useRef(null)
  const retryRequestV2 = useCallback(async (_retry = 3, _delay = 3000, _success = "idle") => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async function (resolve, reject) {
      try {
        if (_retry <= 0 && _success !== 'success') {
          clearTimeout(toRef.current)
          setDownloadCheckLoading(false)
          reject(_success)
        }
        const response = await checkPayment()
        if (!response.link) throw new Error("failed")
        // if (!response.link) reject(new Error("failed"))
        setDownloadCheckLoading(false)
        resolve()
      } catch (e: any) {
        const { message = "" } = e
        if (_retry > 0) toRef.current = setTimeout(() => {
          retryRequestV2(_retry - 1, _delay, message).then(() => resolve())
        }, _delay)
      }
    })
  }, [checkPayment])

  // Check campaign claim info
  const checkCampaignClaim = useCallback(async () => {
    try {
      const response = await handleCheckCampaignClaim(has(content, "token") ? content.token : null)
      if (response.error === false) {
        setCampaignClaimInfo(response.data)
      } else {
        setCampaignClaimInfo(initCampaignClaimInfo)
      }
    } catch (e: any) {
      setCampaignClaimInfo(initCampaignClaimInfo)
    }
  }, [handleCheckCampaignClaim, initCampaignClaimInfo, content])

  // handle gift icon claim
  const onGiftClaim = async (certId: number) => {
    setSelectedClaimCertId(certId)
    openReward()
  }

  // set certificate privacy mode (mode T = public, F = private)
  const setCertPrivacy = async (certId: number, mode: boolean) => {
    setInAction(true)
    try {
      const valueToChange = mode ? 'public' : 'private'
      const response = await handleSetCertPrivacy({
        certificate_id: certId, mode: valueToChange
      }, has(content, "token") ? content.token : null)
      if (response.error) throw new Error(response.message)
      setStateObj({
        latestListing: stateObj.latestListing.map(obj => {
          if (obj.certificate_id === certId) return { ...obj, is_private: !mode }
          return obj
        })
      })
      // const index = stateObj.latestListing.findIndex(obj => (obj.certificate_id === certId))
      // if (index !== -1) {

      // }
    } catch (e: any) {
      const { message = "" } = e
      notify(intl.formatMessage({
        id: 'toast-error-msg-general',
        defaultMessage: 'Please try again. Message: {message}',
      }, { message }), 'error', {
        title: intl.formatMessage({
          id: 'toast-error-title',
          defaultMessage: 'Error'
        })
      })
      // setStateObj({ latestListing: stateObj.latestListing.map(obj => {
      //   if (obj.certificate_id === certId) return { ...obj, is_private: mode === 'private' }
      // })})
    }
    setInAction(false)
  }

  // upload profile picture
  const uploadProfilePicture = async (filetoUpload: File) => {
    setInAction(true)
    try {
      const response = await handleUploadProfilePic(filetoUpload,
        has(content, "token") ? content.token : null)
      if (response.error) throw new Error(response.message)
      const latestInfoJwtResponse = await handleRequestJwt(has(content, "token") ? content.token : null)
      setAuthDetails(latestInfoJwtResponse.data)
      authDetailsRef.current = latestInfoJwtResponse.data
      if (latestInfoJwtResponse.data.token) {
        const decodedJwt = jwtDecode<JwtPayload>(latestInfoJwtResponse.data.token)
        const jwtExpires = new Date(decodedJwt.exp * 1000)
        setCookie('_acc', JSON.stringify({ ...decodedJwt, token: latestInfoJwtResponse.data.token }),
          {
            sameSite: true, secure: true,
            expires: jwtExpires, path: '/'
          })
      }
    } catch (e: any) {
      const { message = "" } = e
      notify(intl.formatMessage({
        id: 'toast-error-msg-general',
        defaultMessage: 'Please try again. Message: {message}',
      }, { message }), 'error', {
        title: intl.formatMessage({
          id: 'toast-error-title',
          defaultMessage: 'Error'
        })
      })
    }
    setInAction(false)
  }

  // To get user latest listing
  const getUserLatestListing = useCallback(async (_userId) => {
    const result = await handleUserCertListing(_userId, stateObj.listCurrentPage, stateObj.listMaxPerPage, has(content, "token") ? content.token : null, selectedCertCategoryValue)
    setStateObj({ latestListing: result.data, listMaxPage: result.max, totalItem: result.total })
  }, [handleUserCertListing, content, stateObj.listCurrentPage, stateObj.listMaxPerPage, selectedCertCategoryValue])

  const getUserLatestListingMobileVersion = useCallback(async (_userId) => {
    const result = await handleUserCertListingMobileVersion(_userId, has(content, "token") ? content.token : null, selectedCertCategoryValue)
    setMobileStateObj({ latestListing: result.data })
  }, [handleUserCertListingMobileVersion, content, selectedCertCategoryValue])

  const fetchMoreData = () => {
    if (stateObj.totalItem === stateObj.latestListing.length) {
      setTimeout(() => {
        setDataSource(stateObj.latestListing.concat(Array.from({ length: 20 })));
      }, 500);
    }
    else {
      setHasMore(false);
    }

  }

  // To get user check in log listing
  const getUserCheckInLogListing = useCallback(async (_userId) => {
    const result = await handleUserCheckInLogListing(_userId, CheckInStateObj.listCurrentPage, CheckInStateObj.listMaxPerPage,has(content, "token") ? content.token : null)
    setCheckInStateObj({ latestCheckInListing: result.data.user_checkin, listMaxPage: result.max, totalItem: result.total })
  }, [handleUserCheckInLogListing, CheckInStateObj.listCurrentPage, CheckInStateObj.listMaxPerPage,content])

  const verifyUserDetails = useCallback(async () => {
    // console.log("verifyUserDetails", tokenValidity, content)
    if (tokenValidity && content) {
      const result = await handleCheckAuth(content.token)
      if (result.error === false) {
        setAuthDetails(result.data)
        authDetailsRef.current = result.data
        setExtTokenValidity(true)
        const kycResponse = await handleCheckKyc(
          has(result.data, "email") ? result.data.email : null, content.token
        )
        setUserKycStatus(kycResponse.error === false ? kycResponse.data : kycStatus.PENDING)
      } else {
        setAuthDetails(initUserDetails)
        authDetailsRef.current = initUserDetails
        setExtTokenValidity(false)
        setUserKycStatus(kycStatus.PENDING)
      }
    }
    if (userId) {
      const result = await handleGetUserDetails(userId)
      if (result.error === false) {
        setUserDetails(result.data)
        userDetailsRef.current = result.data
      } else {
        setUserDetails(initUserDetails)
        userDetailsRef.current = initUserDetails
      }
    }

    // console.log("CURRENT USER ID: ", userId || authDetailsRef.current.user_id || null)
    return userId || authDetailsRef.current.user_id || null
  }, [handleCheckAuth, handleCheckKyc, handleGetUserDetails, tokenValidity, initUserDetails, userId, content])

const copyToClipBoard = (text: string) => {
  console.log(text);
  navigator.clipboard.writeText(text);
  setCopySuccess('Copied!');
}

  // To get cert categories
  const getCertCategories = useCallback(async () => {
    try {
      const result = await handleGetCertCategories()
      if (result.error) {
        throw new Error(result.message)
      }
      setCertCategoriesList([...initialCertCategoryList, ...result.data])
    } catch (error) {
      setCertCategoriesList(initialCertCategoryList)
    }
  }, [handleGetCertCategories, initialCertCategoryList])

  useEffect(() => {
    if (!stateObj.loading && query.get('success') && !isModalOpened) {
      openPayment()
      setIsModalOpen(true)
    }
  }, [stateObj.loading, query, certDownloadInfo, openPayment, isModalOpened])

  useEffect(() => {
    // scroll to top
    window.scrollTo(0, 0)
  }, [])

  const firstRender = useRef(true)
  useLayoutEffect(() => {
    // to prevent trigger action below on first render
    if (firstRender.current) {
      firstRender.current = false
      return;
    }
    const elem = document.getElementById('list-title')
    if (elem) scrollIntoViewWithOffset('list-title', 30)
    // elem.scrollIntoView({ behavior: 'smooth' })
  }, [stateObj.listCurrentPage])

  useEffect(() => {
    verifyUserDetails().then((_userId) => getUserLatestListing(_userId))
      .then(() => getCertCategories())
      .then(() => checkWalletBalance())
      .then(() => checkCampaignClaim())
      .then(() => checkPaymentGatewayStatus())
      .then(() => setStateObj({ loading: false }))
    verifyUserDetails().then((_userId) => getUserCheckInLogListing(_userId))
      .then(() => setCheckInStateObj({ loading: false }))
    verifyUserDetails().then((_userId) => getUserLatestListingMobileVersion(_userId))
      .then(() => getCertCategories())
      .then(() => checkWalletBalance())
      .then(() => checkCampaignClaim())
      .then(() => checkPaymentGatewayStatus())
      .then(() => setMobileStateObj({ loading: false }))
  }, [
    verifyUserDetails, getUserLatestListing, checkCampaignClaim, checkPaymentGatewayStatus,
    checkWalletBalance, getCertCategories, getUserCheckInLogListing, getUserLatestListingMobileVersion
  ])

  useLayoutEffect(() => {
    retryRequestV2()
  }, [retryRequestV2])

  useEffect(() => {
    if (displayOwnProfile() && !authDetails.profile_picture) {
      openProfileImgReminder()
    }
  }, [authDetails.profile_picture, openProfileImgReminder, displayOwnProfile])

  const [showtab, setShowtab] = useState(1);
  const handletab = (e) => {
    setShowtab(e);
  }

  const getFormattedDateStr = (date: string) => {
    if (!date || date === "") return "-"
    const gDate = new Date(date)
    const dateStr = gDate.toLocaleString("en-MY", { day: 'numeric', month: 'short', year: 'numeric' })
    return dateStr
  }

  const getstandardDatetimeStr = (date: string) => {
    if (!date || date === "") return "-"
    const newdate = new Date(date);
    const formatter = new Intl.DateTimeFormat('en-MY', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    const formattedTime = formatter.format(newdate);
    return formattedTime
  }
  const arrayDataItems = CheckInStateObj?.latestCheckInListing?.map(checkIn =>
    <li style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} key={checkIn.id}>
      <p>{checkIn.uni_name}</p>
      <span>{getFormattedDateStr(checkIn.created_at)} {getstandardDatetimeStr(checkIn.created_at)}</span>
    </li>
  )
  return (
    <>
      <PaymentModal opened={paymentOpened} onClose={closePayment} loading={downloadCheckLoading}
        success={(query.get('success') === 'true' && certDownloadInfo.link.length > 0)}
        link={certDownloadInfo.link} message={certDownloadErrMsg}
      />
      <EmailModal opened={emailOpened} onClose={closeEmail} />
      <RewardModal rewardAmount={campaignClaimAmount} opened={rewardOpened} onClose={closeReward} onClaimReward={() => onClaimReward()} inAction={inAction} />
      <WithdrawModal opened={withdrawOpened} onClose={closeWithdraw} withdrawAmount={rewardAmount.toFixed(4).toString()} minClaimAmount={minClaimAmount} />
      <WithdrawListModal opened={withdrawListingOpened} onClose={closeWithdrawListing} />
      <ProfileImgReminderModal opened={ProfileImgReminderOpened} onClose={closeProfileImgReminder} />
      <BannerSection className='hidedetails' />
      <BannerContainer justify='space-between' align='center' maw={1160} w='100%' m='auto' top='-0px' pos='relative'>
        {/* Mobile */}
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexGrow: 1, paddingTop: "0.5rem", paddingBottom: "0.5rem", marginLeft:"1.25rem", marginRight:"1.25rem" }} className="hidewithSizeMoreThan800">
          <div
            style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", position: "relative" }}
          >
            <img src={displayOwnProfile() && authDetails.profile_picture ? authDetails.profile_picture : '/images/profile/Userprofile.svg'} alt='myprofile' style={{
              width: 154,
              height: 154,
              borderRadius: 100,
              objectFit: "cover"
            }} className='changeImg' />
            {displayOwnProfile() && <StyledUploadWrapper direction='column' pos='absolute' justify='center' align='center' h='100%' w='100%' maw='150px'>
              <FileButton accept='image/png,image/jpeg' multiple={false} disabled={inAction} onChange={(file) => uploadProfilePicture(file)}>
                {(props) => <BsFillPlusCircleFill {...props} size={40} fill='var(--btnMainColor)' cursor={inAction ? 'not-allowed' : 'pointer'} className='changePlusIcon' />}
              </FileButton>
              <StyledUploadLabel>
                <FormattedMessage id='label-upload-profile-img' defaultMessage='Upload Image' />
              </StyledUploadLabel>
            </StyledUploadWrapper>}
            <div
              style={{ display: "flex", justifyContent: "center", alignItems: "center", position: "relative", gap: "8px", paddingLeft: "10px", paddingRight: "10px" }}
            >
              <p
                style={{ fontSize: "18px", fontWeight: "700", textAlign: "left", color: "#313759", width: "fit-content", overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: 1,
                wordWrap: 'break-word',
                wordBreak: 'break-all',
                WebkitBoxOrient: 'vertical',
                 }}
              >
                {displayOwnProfile() ? `${authDetails.full_name}` : `${userDetails.full_name}` || '-'}
              </p>
              <div>
                {
                  loginValidity() ?
                    (userKycStatus === kycStatus.APPROVED ?
                      <Link to="/alumni-kyc">
                        <Flex mah="300px"
                          style={{ borderRadius: "20px", overflowY: "auto" }}
                        >
                          <img src="/images/tick-circle.svg" alt="green-tick" style={{ maxWidth: "24px", maxHeight: "24px" }} />
                        </Flex>
                      </Link>
                      :
                      <Link to="/alumni-kyc">
                        <Flex mah="300px"
                          style={{ borderRadius: "20px", overflowY: "auto" }}
                        >
                          <img src="/images/clock.svg" alt="clock-tick" style={{ maxWidth: "24px", maxHeight: "24px" }} />
                        </Flex>

                      </Link>)
                    :
                    null
                }
              </div>
            </div>
          </div>
          <div
            style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", position: "relative", gap: "10px" }}
          >
            {loginValidity() &&
              <div style={{ display: 'none', zIndex: '1' }} className='showdetails'>
                <StyledButton borderRadius="8px" minHeight="44px" width="auto" padding="2" bgColorCode='#FFFFFF'
                  height="auto" onClick={() => openEmail()}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 9.08V6.25C9.59 6.25 9.25 5.91 9.25 5.5V3.25H7C2.59 3.25 1.25 4.59 1.25 9V9.5C1.25 9.91 1.59 10.25 2 10.25C2.96 10.25 3.75 11.04 3.75 12C3.75 12.96 2.96 13.75 2 13.75C1.59 13.75 1.25 14.09 1.25 14.5V15C1.25 19.41 2.59 20.75 7 20.75H9.25V18.5C9.25 18.09 9.59 17.75 10 17.75V14.92C9.59 14.92 9.25 14.58 9.25 14.17V9.83C9.25 9.42 9.59 9.08 10 9.08Z" fill="#835FE9" />
                    <path opacity="0.4" d="M20.25 12.5C20.25 13.46 21.04 14.25 22 14.25C22.41 14.25 22.75 14.59 22.75 15C22.75 19.41 21.41 20.75 17 20.75H10.75V18.5C10.75 18.09 10.41 17.75 10 17.75V14.92C10.41 14.92 10.75 14.58 10.75 14.17V9.83C10.75 9.42 10.41 9.08 10 9.08V6.25C10.41 6.25 10.75 5.91 10.75 5.5V3.25H17C21.41 3.25 22.75 4.59 22.75 9V10C22.75 10.41 22.41 10.75 22 10.75C21.04 10.75 20.25 11.54 20.25 12.5Z" fill="#835FE9" />
                  </svg>

                </StyledButton>
              </div>
            }

          </div>
        </div>
        {/* Web */}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
            position: "absolute",
            top: '-4rem',
            left: 0,
            right: 0,
            padding: '1.25rem',
          }} className='changeTop hidewithSize'
        >
          <div
            style={{
              display: "flex",
              alignItems: "end",
              gap: "1rem",
            }}
          >
            <img src={displayOwnProfile() && authDetails.profile_picture ? authDetails.profile_picture : '/images/profile/Userprofile.svg'} alt='myprofile' style={{
              width: 154,
              height: 154,
              borderRadius: 100,
              objectFit: "cover"
            }} className='changeImg' />
            {displayOwnProfile() && <StyledUploadWrapper direction='column' pos='absolute' justify='center' align='center' h='100%' w='100%' maw='150px'>
              <FileButton accept='image/png,image/jpeg' multiple={false} disabled={inAction} onChange={(file) => uploadProfilePicture(file)}>
                {(props) => <BsFillPlusCircleFill {...props} size={40} fill='var(--btnMainColor)' cursor={inAction ? 'not-allowed' : 'pointer'} className='changePlusIcon' />}
              </FileButton>
              <StyledUploadLabel>
                <FormattedMessage id='label-upload-profile-img' defaultMessage='Upload Image' />
              </StyledUploadLabel>
            </StyledUploadWrapper>}
            <div style={{
              display: "flex",
              flexDirection: "column",
              zIndex: 1
            }}>
              {/* user name */}

              <p style={{
                fontSize: 25,
                fontWeight: 700,
                textAlign: "left",
                color: "#23262f",
                height: "10%",
                overflow: "hidden"
              }} className='smallscreen'>
                {displayOwnProfile() ? authDetails.full_name : userDetails.full_name || '-'}
              </p>

              <div>
                {
                  loginValidity() ?
                    (userKycStatus === kycStatus.APPROVED ?
                      <Link to="/alumni-kyc">
                        <Flex p="clamp(5px, 8vw, 10px) clamp(5px, 8vw, 20px)" bg="#835FE9" mah="300px"
                          style={{ borderRadius: "20px", overflowY: "auto" }}
                        >
                          <p id='kyc-status-approved' style={{ color: 'white' }} className='kycsmallscreen'>KYC Approved</p>
                          <img src="/images/green-tick.png" alt="green-tick" style={{ maxWidth: "24px", maxHeight: "24px" }} />
                        </Flex>
                      </Link>
                      :
                      <Link to="/alumni-kyc">
                        <StyledButton borderRadius="20px" minHeight="auto" width="auto" padding="0 5px"
                          height="34px" minWidth="126px">
                          <StyledText colorCode='var(--whiteColor)' transform='uppercase'>
                            <FormattedMessage
                              id='profile-subtitle1'
                              defaultMessage="Complete Your KYC"
                            />
                          </StyledText>
                        </StyledButton>
                      </Link>)
                    :
                    null
                }
              </div>
            </div>
          </div>
          <div style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-start",
            position: "relative",
          }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                position: "relative",
                gap: 7
              }}
            >
              {loginValidity() &&
                <div style={{ display: 'none', zIndex: '1' }} className='showdetails'>
                  <StyledButton borderRadius="8px" minHeight="44px" width="auto" padding="2" bgColorCode='#FFFFFF'
                    height="auto" onClick={() => openEmail()}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10 9.08V6.25C9.59 6.25 9.25 5.91 9.25 5.5V3.25H7C2.59 3.25 1.25 4.59 1.25 9V9.5C1.25 9.91 1.59 10.25 2 10.25C2.96 10.25 3.75 11.04 3.75 12C3.75 12.96 2.96 13.75 2 13.75C1.59 13.75 1.25 14.09 1.25 14.5V15C1.25 19.41 2.59 20.75 7 20.75H9.25V18.5C9.25 18.09 9.59 17.75 10 17.75V14.92C9.59 14.92 9.25 14.58 9.25 14.17V9.83C9.25 9.42 9.59 9.08 10 9.08Z" fill="#835FE9" />
                      <path opacity="0.4" d="M20.25 12.5C20.25 13.46 21.04 14.25 22 14.25C22.41 14.25 22.75 14.59 22.75 15C22.75 19.41 21.41 20.75 17 20.75H10.75V18.5C10.75 18.09 10.41 17.75 10 17.75V14.92C10.41 14.92 10.75 14.58 10.75 14.17V9.83C10.75 9.42 10.41 9.08 10 9.08V6.25C10.41 6.25 10.75 5.91 10.75 5.5V3.25H17C21.41 3.25 22.75 4.59 22.75 9V10C22.75 10.41 22.41 10.75 22 10.75C21.04 10.75 20.25 11.54 20.25 12.5Z" fill="#835FE9" />
                    </svg>

                  </StyledButton>
                </div>
              }

            </div>
            {loginValidity() &&
              <div className='hidedetails'>
                <StyledButton borderRadius="8px" minHeight="44px" width="auto" padding="2" bgColorCode='#FFFFFF'
                  margin='auto' height="auto" minWidth="126px" onClick={() => openEmail()}>
                  <StyledText colorCode='var(--textGrey)' fWeight="normal" fSize="15px">
                    <FormattedMessage
                      id='btn-label-submit-ticket'
                      defaultMessage="Submit a Ticket"
                    />
                  </StyledText>
                </StyledButton>
              </div>
            }
          </div>
        </div>

        <div style={{
          display: 'column',
          width: '100%',
          backgroundColor: '#313759',
          padding: '18px',
          margin: '10rem 1.25rem 0 1.25rem',
          borderRadius: '16px',
        }} className='mobile-view'>

          <Flex wrap="wrap" align="center" gap="1rem" style={{ width: "100%" }}>
            <Flex direction="column" style={{ width: "100%" }}>
              <StyledText colorCode="white" fWeight="normal" fSize="18px">
                <FormattedMessage
                  id='profile-label-reward'
                  defaultMessage="Reward"
                />
              </StyledText>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <StyledText fWeight="normal" fSize="36px" colorCode="var(--whiteColor)">
                  {displayOwnProfile() ? `${rewardAmount.toFixed(2)}` : '-' || '-'}
                </StyledText>

                {Number(rewardAmount) > 0.00 && (tokenValidity && userKycStatus === kycStatus.APPROVED) && <StyledButton style={{ marginTop: "10px", paddingLeft: "6px", paddingRight: "6px", paddingTop: "2px", paddingBottom: "2px", borderRadius: "5px", background: "#835fe9", height: "30px" }}
                  onClick={() => openWithdraw()}>
                  <p
                    style={{ fontSize: "12px", fontWeight: 700, textAlign: "left", color: "#fff" }}
                  >
                    Claim Reward
                  </p>
                </StyledButton>}
              </div>

            </Flex>
          </Flex>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <StyledText colorCode="var(--whiteColor)" fWeight="normal" fSize="10px" width="fit-content"
            >
              <FormattedMessage
                id='btn-label-wallet-address'
                defaultMessage="Wallet Address : "
              />
              {displayOwnProfile() && authDetails?.wallet_address ? `${authDetails.wallet_address.substring(0, 12)}...${authDetails.wallet_address.substring(38)}` : '-'}
              {displayOwnProfile() && authDetails?.wallet_address && <button style={{ backgroundColor: "transparent", border: "none" }} type='button' onClick={() => copyToClipBoard(authDetails.wallet_address)}>
                <i className="pi pi-copy" style={{ color: "#708090" }} /> {copySuccess}
              </button>}
            </StyledText>

            {tokenValidity && <>
              <button type='button' onClick={() => openWithdrawListing()}
                style={{ backgroundColor: "transparent", border: "none", cursor: "pointer" }}>
              <img src="/images/receipt-text-white.svg" alt="green-tick" style={{ maxWidth: "24px", maxHeight: "24px" }} />
              </button>
              

            </>}
          </div>
        </div>
      </BannerContainer>

      <section style={{ paddingBottom: "5rem", padding: "0 20px" }}>
        <Flex justify='center' direction='column' m='auto' maw={1160}>
          <div className="btn-group" style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start", gap: 15, padding: "10px", width: "100%" }}>
            <button type='button' className={showtab === 1 ? "hover-button nav-link active" : "hover-button nav-link"} onClick={() => handletab(1)}
              style={{
                display: "flex", justifyContent: "center", alignItems: 'center', alignSelf: 'stretch', borderRadius: "8px", background: '#fff', borderWidth: '1px', borderColor: '#313759',
                width: "-webkit-fill-available"
              }} >
              <div>
                <p style={{ fontSize: 12, textAlign: "center", color: "#313759", padding: "0 10px 0 10px" }}>
                  Digital Asset
                </p>
              </div>
            </button>
            {/* <button type='button' className={showtab === 2 ? "hover-button nav-link active" : "hover-button nav-link"} onClick={() => handletab(2)}>
              <div>
                <p style={{ fontSize: 16, textAlign: "center" }}>
                  Rewards
                </p>
              </div>
            </button> */}
            <button type='button' className={showtab === 3 ? "hover-button nav-link active" : "hover-button nav-link"} onClick={() => handletab(3)}
              style={{
                display: "flex", justifyContent: "center", alignItems: 'center', alignSelf: 'stretch', paddingLeft: '20px',
                paddingRight: '20px', paddingTop: "10px", paddingBottom: "10px", borderRadius: "8px", background: '#fff', borderWidth: '1px', borderColor: '#313759',
                width: "-webkit-fill-available"
              }}>
              <div>
                <p style={{ fontSize: 10, textAlign: "center", color: "#313759", padding: "0 10px 0 10px" }}>
                  Check-In History
                </p>
              </div>
            </button>
            {/* <div
              style={{
                display: "flex", justifyContent: "center", alignItems: "center", alignSelf: "stretch", position: "relative",
                overflow: "hidden", gap: "8px", paddingLeft: "18px", paddingRight: "18px", borderRadius: "8px", background: "#fff",
                borderWidth: "1px", borderColor: "#313759"
              }}
              className='hidewithSizeMoreThan800'
            >
              <Dropdown>
                <Dropdown.Button css={{
                  background: 'var(--modalColor)', borderRadius: '8px'
                }}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden",
                    borderRadius: 10,
                    background: "#fff",
                    borderWidth: 1,
                    borderColor: "#d9d9d9",
                    paddingLeft: "5px",
                    paddingRight: "5px"
                  }}
                >
                  <svg
                    width={18}
                    height={18}
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ width: 18, height: 18, position: "relative" }}
                    preserveAspectRatio="none"
                  >
                    <path d="M2.25 5.25H15.75H2.25Z" fill="#232323" />
                    <path d="M2.25 5.25H15.75" stroke="#232323" strokeWidth="1.5" strokeLinecap="round" />
                    <path d="M4.5 9H13.5" stroke="#232323" strokeWidth="1.5" strokeLinecap="round" />
                    <path d="M7.5 12.75H10.5" stroke="#232323" strokeWidth="1.5" strokeLinecap="round" />
                  </svg>

                </Dropdown.Button>

                <Dropdown.Menu
                  disallowEmptySelection
                  selectionMode='single'
                  selectedKeys={selectedCertCategory}
                  onSelectionChange={(selected) => setSelectedCategory(selected as Set<string>)}
                >
                  {certCategoriesList.map((category) => <Dropdown.Item key={category.category_name}
                    css={{ h: 'auto', fs: '14px', pt: '5px', pb: '5px' }}
                  >
                    {category.category_name || <FormattedMessage id='label-all-categories' defaultMessage='All Categories' />}
                  </Dropdown.Item>)}
                </Dropdown.Menu>
              </Dropdown>
            </div> */}
          </div>
          <div style={{ display: 'none' }} className={showtab === 1 ? "tab-pane fade show active" : "tab-pane fade show"}>
            {/* {loginValidity() && <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                width: '100%',
              }}
              className='flexStart'
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 35,
                  position: "relative",
                  overflow: "hidden",
                  padding: 12,
                  borderRadius: 10,
                  background: "#fff",
                  borderWidth: 1,
                  borderColor: "#d9d9d9",
                  zIndex: 1
                }}
                className='hidewithSize'
              >
                <Dropdown>
                  <Dropdown.Button css={{
                    background: 'var(--modalColor)', borderRadius: '8px'
                  }}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                      overflow: "hidden",
                      gap: 8,
                      padding: 12,
                      borderRadius: 10,
                      background: "#fff",
                      borderWidth: 1,
                      borderColor: "#d9d9d9",
                    }}
                  >
                    <svg
                      width={18}
                      height={18}
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ flexGrow: 0, flexShrink: 0, width: 18, height: 18, position: "relative" }}
                      preserveAspectRatio="none"
                    >
                      <path d="M2.25 5.25H15.75H2.25Z" fill="#232323" />
                      <path d="M2.25 5.25H15.75" stroke="#232323" strokeWidth="1.5" strokeLinecap="round" />
                      <path d="M4.5 9H13.5" stroke="#232323" strokeWidth="1.5" strokeLinecap="round" />
                      <path d="M7.5 12.75H10.5" stroke="#232323" strokeWidth="1.5" strokeLinecap="round" />
                    </svg>
                    <p
                      style={{
                        flexGrow: 0,
                        flexShrink: 0,
                        fontSize: 14,
                        fontWeight: 600,
                        textAlign: "center",
                        color: "#232323",
                      }}
                    >
                      Category
                    </p>
                  </Dropdown.Button>

                  <Dropdown.Menu
                    disallowEmptySelection
                    selectionMode='single'
                    selectedKeys={selectedCertCategory}
                    onSelectionChange={(selected) => setSelectedCategory(selected as Set<string>)}
                  >
                    {certCategoriesList.map((category) => <Dropdown.Item key={category.category_name}
                      css={{ h: 'auto', fs: '14px', pt: '5px', pb: '5px' }}
                    >
                      {category.category_name || <FormattedMessage id='label-all-categories' defaultMessage='All Categories' />}
                    </Dropdown.Item>)}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

            </div>} */}
            {/* Web */}
            <div className='hidedetails'>
              {stateObj.loading ? <>
                <PageLoader />
              </>
                :
                stateObj.latestListing.length === 0 ?
                  <Flex justify='center' align='center' >
                    <StyledText fSize="1.3em" fWeight="normal" textAlign="center">
                      <FormattedMessage id="label-no-result" defaultMessage="No results found." />
                    </StyledText>
                  </Flex>
                  :
                  <>
                    <ListContainer maxscreennumberperrow={3} smallscreengap='32px 8px' bigscreengap='32px 20px' style={{ margin: 'auto', paddingTop: '10px', gap: "10px" }}>
                      {stateObj.latestListing.map(cert => (
                        <CertItem certInfo={cert}
                          paymentGatewayStatus={paymentGatewayStatus}
                          onPaidDownload={onPaidDownload}
                          certDownloadInfo={certDownloadInfo}
                          checkoutPrint={checkoutPrint}
                          onFreeCheckout={onFreeCheckout}
                          campaignClaimInfo={campaignClaimInfo}
                          onGiftClaim={onGiftClaim}
                          inAction={inAction}
                          setCertPrivacy={setCertPrivacy}
                        />
                      ))}
                    </ListContainer>

                    <div className='container mobile-pagination'>
                      <Pagination className="pagination-bar" currentPage={stateObj.listCurrentPage} totalCount={stateObj.totalItem}
                        pageSize={stateObj.listMaxPerPage} onPageChange={page => setStateObj({ listCurrentPage: page })}
                        useWhite={false}
                      />
                    </div>
                  </>
              }
            </div>
            {/* Mobile */}
            <div className='hidewithSizeMoreThan800'>
              {MobilestateObj.loading ? <>
                <PageLoader />
              </>
                :
                MobilestateObj.latestListing.length === 0 ?
                  <Flex justify='center' align='center' >
                    <StyledText fSize="1.3em" fWeight="normal" textAlign="center">
                      <FormattedMessage id="label-no-result" defaultMessage="No results found." />
                    </StyledText>
                  </Flex>
                  :
                  <>
                    <ListContainer smallscreengap='32px 8px' bigscreengap='32px 20px' style={{ margin: 'auto', paddingTop: '10px', gap: "10px" }}>
                      {MobilestateObj.latestListing.map(cert => (
                        <CertItem certInfo={cert}
                          paymentGatewayStatus={paymentGatewayStatus}
                          onPaidDownload={onPaidDownload}
                          certDownloadInfo={certDownloadInfo}
                          checkoutPrint={checkoutPrint}
                          onFreeCheckout={onFreeCheckout}
                          campaignClaimInfo={campaignClaimInfo}
                          onGiftClaim={onGiftClaim}
                          inAction={inAction}
                          setCertPrivacy={setCertPrivacy}
                        />
                      ))}
                    </ListContainer>

                    <div style={{ height: '100px' }} />
                  </>
              }
            </div>
          </div>
          <div style={{ display: 'none' }} className={showtab === 2 ? "tab-pane fade show active" : "tab-pane fade"}>
            <Flex justify='center' align='center' >
              <StyledText fSize="1.3em" fWeight="normal" textAlign="center">
                <FormattedMessage id="label-no-result" defaultMessage="No results found." />
              </StyledText>
            </Flex>
          </div>
          <div style={{ display: 'none' }} className={showtab === 3 ? "tab-pane fade show active" : "tab-pane fade"}>
            <ul>
              <li style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <p style={{ fontWeight: 700 }}>Merchant</p>
                <p style={{ fontWeight: 700 }}>Date</p>
              </li>
              <hr />
              {arrayDataItems === undefined || arrayDataItems.length === 0 ?

                <Flex justify='center' align='center' >
                  <StyledText fSize="1.3em" fWeight="normal" textAlign="center">
                    <FormattedMessage id="label-no-result" defaultMessage="No results found." />
                  </StyledText>
                </Flex> :
                arrayDataItems

              }
              <div className='container mobile-pagination'>
                <Pagination className="pagination-bar" currentPage={CheckInStateObj.listCurrentPage} totalCount={CheckInStateObj.totalItem}
                  pageSize={CheckInStateObj.listMaxPerPage} onPageChange={page => setCheckInStateObj({ listCurrentPage: page })}
                  useWhite={false}
                />
              </div>
            </ul>
          </div>
        </Flex>
      </section>
      <div style={{
        position: "absolute",
        height: "100vh",
        background: "#F4F4F4",
        width: "100%",
        zIndex: -100
      }} />
    </>
  )
}

export default Profile