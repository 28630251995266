import React, { useEffect, useMemo, useReducer } from 'react'
import { has } from 'lodash'
import BigNumber from 'bignumber.js'
import { FormattedMessage } from 'react-intl'
import { Loader, Modal, Flex } from '@mantine/core'
import { StyledText } from 'components/GlobalStyle'
import Pagination from 'components/Pagination/Pagination'
import { useTokenAuth } from 'hooks/useTokenAuth'
import { withdrawListingStateObjType } from 'config/constants/types'
import { useUserWithdrawalListing } from '../hooks/useWithdrawal'

interface WithdrawListModalProps {
  opened: boolean,
  onClose: () => void
}

const WithdrawListModal: React.FC<WithdrawListModalProps> = ({ opened, onClose }) => {
  const initStateData: withdrawListingStateObjType = useMemo(() => ({
    loading: true, latestListing: [], listCurrentPage: 1, listMaxPage: 0, listMaxPerPage: 10, totalItem: 0
  }), [])
  const [stateObj, setStateObj] = useReducer((state: withdrawListingStateObjType, newState: Partial<withdrawListingStateObjType>) => ({
    ...state, ...newState
  }), initStateData)

  const { tokenValidity, content } = useTokenAuth()
  const { onUserWithdrawalListing : handleUserWithdrawalListing } = useUserWithdrawalListing()

  if (tokenValidity === false) onClose()

  const dateFormat: Intl.DateTimeFormatOptions = {
    day: 'numeric', // numeric, 2-digit
    month: 'short', // numeric, 2-digit, long, short, narrow
    year: 'numeric',
    hour: '2-digit', // numeric, 2-digit
    minute: 'numeric', // numeric, 2-digit
    // second: 'numeric'
  }
  const getFormattedDateStr = (date: string) => {
    if (!date || date === "") return "-"
    const gDate = new Date(date)
    const dateStr = gDate.toLocaleString("en-MY", dateFormat)
    return dateStr
  }

  useEffect(() => {
    const getUserWithdrawalListing = async () => {
      const result = await handleUserWithdrawalListing(
        has(content, 'token') ? content.token : null, stateObj.listCurrentPage, stateObj.listMaxPerPage
      )
      setStateObj({ latestListing: result.data, listMaxPage: result.max, totalItem: result.total })
    }

    if (opened) getUserWithdrawalListing().then(() => setStateObj({ loading: false }))
  }, [handleUserWithdrawalListing, content, stateObj.listCurrentPage, stateObj.listMaxPerPage, opened])

  return (
    <>
      <Modal opened={opened} onClose={onClose} centered closeOnClickOutside size="auto"
        title={<StyledText fSize="20px">
          <FormattedMessage id='claim-list-title' defaultMessage="Claim Request History" />
        </StyledText>}
        styles={() => ({
          content: {
            borderRadius: '12px', background: 'var(--modalColor)'
          },
          header: {
            marginTop: '12px', background: 'var(--whiteColor)',
            borderBottom: '1px solid #F2F3F4'
          },
          body: {
            background: 'var(--whiteColor)',
            // padding: '20px 16px',
            '&:not(:only-child)': { paddingTop: 0 }
          }
        })}
      >
        {stateObj.loading ? <Flex justify="center" align="center" mih="238px">
          <Loader size="xl" style={{ maxWidth: "250px" }} />
        </Flex>
        :
        <Flex direction="column" justify="center" align="center">
          <div className="row" style={{ width: '100%' }}>
            <div className="col-lg-12">
              <div className="table-responsive tableFixHead">
                <table className="table">
                  <thead>
                    <tr>
                      <th><StyledText fSize="1em" fWeight="bold">
                        <FormattedMessage id="label-date" defaultMessage="Date" />
                      </StyledText></th>
                      <th><StyledText fSize="1em" fWeight="bold">
                        <FormattedMessage id="label-amount" defaultMessage="Amount" />
                      </StyledText></th>
                      <th><StyledText fSize="1em" fWeight="bold">
                        <FormattedMessage id="kyc-form-label-bank-name" defaultMessage="Bank Name" />
                      </StyledText></th>
                      <th><StyledText fSize="1em" fWeight="bold">
                        <FormattedMessage id="label-acc-holder" defaultMessage="Account Holder" />
                      </StyledText></th>
                      <th><StyledText fSize="1em" fWeight="bold">
                        <FormattedMessage id="label-bank-acc" defaultMessage="Bank Account" />
                      </StyledText></th>
                      <th><StyledText fSize="1em" fWeight="bold">
                        <FormattedMessage id="label-status" defaultMessage="Status" />
                      </StyledText></th>
                    </tr>
                  </thead>
                  {stateObj.latestListing.length === 0 ?
                    <tbody>
                      <tr>
                        <td colSpan={7}>
                          <StyledText fSize="1.3em" fWeight="normal" textAlign="center">
                            <FormattedMessage id="label-no-result" defaultMessage="No results found." />
                          </StyledText>
                        </td>
                      </tr>
                    </tbody>
                    :
                    <tbody>
                      {stateObj.latestListing.map(record => (
                        <tr key={record.created_at}>
                          <td>
                            <StyledText colorCode='var(--textGrey)' fSize="1em" fWeight="normal" >
                              {getFormattedDateStr(record.created_at)}
                            </StyledText>
                          </td>
                          <td>
                            <StyledText colorCode='var(--textGrey)' fSize="1em" fWeight="normal" >
                              {new BigNumber(record.amount).toFixed(2)}
                            </StyledText>
                          </td>
                          <td>
                            <StyledText colorCode='var(--textGrey)' fSize="1em" fWeight="normal" >
                              {record.bank_name}
                            </StyledText>
                          </td>
                          <td>
                            <StyledText colorCode='var(--textGrey)' fSize="1em" fWeight="normal" >
                              {record.account_holder}
                            </StyledText>
                          </td>
                          <td>
                            <StyledText colorCode='var(--textGrey)' fSize="1em" fWeight="normal" >
                              {record.bank_account_number}
                            </StyledText>
                          </td>
                          <td>
                            <StyledText colorCode='var(--textGrey)' fSize="1em" fWeight="normal" transform="capitalize" >
                              {record.status}
                            </StyledText>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  }
                </table>
              </div>
              <Pagination className="pagination-bar" currentPage={stateObj.listCurrentPage} totalCount={stateObj.totalItem} pageSize={stateObj.listMaxPerPage} onPageChange={page => setStateObj({ listCurrentPage: page })} useWhite={false} />
            </div>
          </div>
        </Flex>
        }
      </Modal>
    </>
  )
}

export default WithdrawListModal