import React, { useCallback, useRef, useState , useMemo } from 'react'
import { useNotifications } from 'reapop'
import * as Yup from 'yup'
import { Modal, Flex, Input, TextInput } from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { useTimeout } from '@mantine/hooks'
import { FormattedMessage, useIntl } from 'react-intl'
import { Turnstile as TT, TurnstileInstance } from '@marsidev/react-turnstile'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { useCookies } from 'react-cookie'
import { StyledButton, StyledText } from 'components/GlobalStyle'
import { debounce } from 'lodash'
import { useRequestOtp, useLogin } from './hooks/useAuth'


interface LoginModalProps {
  opened: boolean,
  onClose?: () => void
}

const LoginModal: React.FC<LoginModalProps> = ({ opened, onClose }) => {
  const intl = useIntl()
  const { notify } = useNotifications()

  const [inAction, setInAction] = useState(false)
  const [otpSent, setOtpSent] = useState(false)
  const turnstileWidgetRef = useRef<TurnstileInstance>(null)
  const [turnstile, setTurnstile] = useState("")
  const [turnstileError, setTurnstileError] = useState(false)
  const [showLoginError, setShowLoginError] = useState(false)

  const [cookies, setCookie, removeCookie] = useCookies()

  const { start: resetOtpSent, clear } = useTimeout(() => {setOtpSent(false)}, 60000)
  const { onRequestOtp : handleRequestOtp } = useRequestOtp()
  const { onLogin : handleLogin } = useLogin()

  const schema = Yup.object().shape({
    email: Yup.string().required(intl.formatMessage({
      id: "form-error-req-email", defaultMessage: "Email is required"
    })).email(intl.formatMessage({
      id: "form-error-invalid-email", defaultMessage: "Email is invalid"
    })),
    password: Yup.string().required(intl.formatMessage({
      id: "form-error-req-code", defaultMessage: "Code is required"
    }))  // .test('len', 'Must be exactly 5 characters', val => val.length === 5)
  })

  const form = useForm({
    initialValues: {
      email: '',
      password: ''
    },
    validate: yupResolver(schema)
  })

  const actionOnClose = () => {
    onClose()
    form.reset()
  }

  const requestOtp = useCallback(async () => {
    // check email validation
    if (form.isValid('email') === false) {
      notify(intl.formatMessage({
        id: 'toast-error-msg-email-invalid',
        defaultMessage: 'Invalid email'
      }), 'error', {
        title: intl.formatMessage({
          id: 'toast-error-title',
          defaultMessage: 'Error'
        })
      })
      return ;
    }
    try {
      const response = await handleRequestOtp(form.values.email, turnstile)
      if (response.error || response.message !== "OK") throw new Error(response.message)
      setOtpSent(true)
      setTurnstileError(false)
      resetOtpSent()
    } catch (e: any) {
      const { message = "" } = e
      if (message.includes("Invalid captcha response")) {
        notify(intl.formatMessage({
          id: 'toast-error-msg-captcha',
          defaultMessage: 'Captcha error'
        }), 'error', {
          title: intl.formatMessage({
            id: 'toast-error-title',
            defaultMessage: 'Error'
          })
        })
        setTurnstileError(true)
      } else if (message.includes("User not found")) {
        notify(intl.formatMessage({
          id: 'toast-error-msg-user-invalid',
          defaultMessage: 'User not found'
        }), 'error', {
          title: intl.formatMessage({
            id: 'toast-error-title',
            defaultMessage: 'Error'
          })
        })
        turnstileWidgetRef.current.reset()
      } else {
        notify(intl.formatMessage({
          id: 'toast-error-msg-general2',
          defaultMessage: 'Please try again.',
        }), 'error', {
          title: intl.formatMessage({
            id: 'toast-error-title',
            defaultMessage: 'Error'
          }), 
        })
      }
    }
  }, [handleRequestOtp, form, resetOtpSent, notify, intl, turnstile])

 

  const handleSubmit = async () => {
    setInAction(true)
    try {
      const response = await handleLogin(form.values.email, form.values.password)
      if (response.error) throw new Error(response.message)
      if (response.data.token) {
        const decodedJwt = jwtDecode<JwtPayload>(response.data.token)
        const jwtExpires = new Date(decodedJwt.exp * 1000)
        console.log("JWT GET: ", { ...decodedJwt, token: response.data.token })
        setCookie('_acc', JSON.stringify({ ...decodedJwt, token: response.data.token }), 
        { 
          sameSite: true, secure: true,
          expires: jwtExpires, path: '/'
        })
        setShowLoginError(false)
        setInAction(false)
        onClose()
        actionOnClose()
        if(!window.location.pathname.includes("/checkin"))
          {
            window.location.pathname = "/profile";
          }

        if(window.location.href.includes('?login=true'))
          {

            window.location.href = '/profile';
          }
      }
    } catch (e: any) {
      const { message = "" } = e
      if (message.includes("Unauthorized")) {
        // toastError(t('Error'), t('Login failed. Please verify the validity of your code.'))
        setShowLoginError(true)
      } else if (message.includes("User not found")) {
        notify(intl.formatMessage({
          id: 'toast-error-msg-user-invalid',
          defaultMessage: 'User not found'
        }), 'error', {
          title: intl.formatMessage({
            id: 'toast-error-title',
            defaultMessage: 'Error'
          })
        })
      } else {
        notify(intl.formatMessage({
          id: 'toast-error-msg-general2',
          defaultMessage: 'Please try again.',
        }), 'error', {
          title: intl.formatMessage({
            id: 'toast-error-title',
            defaultMessage: 'Error'
          })
        })
      }
      setInAction(false)
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const debouncedClick = useMemo(() => debounce(requestOtp, 500, {leading: true, trailing: false}), []);
  const debouncedClick = debounce(requestOtp, 500);
  return (
    <>
      <Modal opened={opened} onClose={() => actionOnClose()} centered closeOnClickOutside
        title={<StyledText fSize='20px'>
          <FormattedMessage id='login-title' defaultMessage="Log In Account" />
        </StyledText>}
        styles={() => ({
          content: {
            borderRadius: '12px', background: 'var(--modalColor)'
          },
          header: {
            marginTop: '12px', background: 'var(--whiteColor)',
            borderBottom: '1px solid #F2F3F4'
          },
          body: {
            background: 'var(--whiteColor)',
            padding: '20px',
            '&:not(:only-child)': { paddingTop: 0 }
          }
        })}
      >
        {showLoginError && <div style={{ paddingTop: '10px' }}>
          <Flex justify='center' align='center' bg='rgba(245, 56, 56, 0.12)' p={10} style={{ borderRadius: '8px' }}>
            <img src='/images/modal/red-info.svg' alt='red-info' />
            <StyledText colorCode='#D20319' fSize='14px' fWeight='normal' >
              <FormattedMessage id='login-error-msg-otp-invalid' defaultMessage="The OTP code you entered is invalid. Please try again." />
            </StyledText>
          </Flex>
        </div>}
        <Flex align="center" direction="column" pt={10} style={{ gap: "1rem" }}>
          <Input.Wrapper
            label={
              <Flex align="center" style={{ gap: "5px" }}>
                <StyledText fWeight='normal' colorCode='var(--textBlack)'>
                  <FormattedMessage id='profile-label-email' defaultMessage="Email" />
                </StyledText>
              </Flex>}
            style={{ width: '100%', maxWidth: '427px' }}
          >
            <TextInput id='email-input' placeholder='Enter your email' radius={8} {...form.getInputProps('email')} />
          </Input.Wrapper>

          <Input.Wrapper
            label={
              <Flex align="center" style={{ gap: "5px" }}>
                <StyledText fWeight='normal' colorCode='var(--textBlack)'>
                  <FormattedMessage id="form-label-email-verify" defaultMessage="Email Verification" />
                </StyledText>
              </Flex>}
            style={{ width: '100%', maxWidth: '427px' }}
          >
            <TextInput id='code-input' placeholder='Enter code' radius={8}
              rightSection={
                <div style={{ marginRight: "5.2rem" }}>
                  {otpSent === true ? <StyledButton
                    bgColorCode="var(--whiteColor)"
                    width="auto"
                    height="30px"
                    minWidth="100px"
                    minHeight="auto"
                    padding="0"
                    borderRadius="20px"
                    border='1px solid #1DA053'
                  >
                    <img src='/images/modal/sent-icon.svg?v=2' alt='sent' style={{ marginRight: '5px' }} />
                    <StyledText colorCode='#1DA053' fSize='14px' >
                      <FormattedMessage
                        id='btn-label-sent'
                        defaultMessage="Sent"
                      />
                    </StyledText>
                  </StyledButton>
                  :
                  <StyledButton
                    bgColorCode="#F2F3F4"
                    width="auto"
                    height="30px"
                    minWidth="100px"
                    minHeight="auto"
                    padding="0"
                    borderRadius="20px"
                    onClick={debouncedClick}
                    disabled={turnstileError === true || otpSent}
                  >
                    <StyledText fWeight='normal' colorCode='var(--textGrey)' fSize='14px' >
                      <FormattedMessage
                        id='btn-label-send-code'
                        defaultMessage="Send Code"
                      />
                    </StyledText>
                  </StyledButton>
                  }
                </div>
              }
              {...form.getInputProps('password')}
              onChange={(event) => {
                form.setFieldValue('password', event.target.value.replace(/\s+/g, ''))
              }}
            />
          </Input.Wrapper>

          <TT id='turnstile-widget' ref={turnstileWidgetRef} siteKey={process.env.REACT_APP_TT_SITEKEY}
            options={{
              language: 'en'
            }}
            onSuccess={(token) => {
              setTurnstile(token)
              setTurnstileError(false)
            }}
            onError={() => setTurnstileError(true)}
          />

          <StyledButton borderRadius="200px" width="auto"
            height="auto" minWidth="130px" minHeight="48px"
            onClick={form.onSubmit(handleSubmit)} disabled={inAction}  >
            <StyledText colorCode='var(--whiteColor)' transform='uppercase'>
              <FormattedMessage id='btn-label-login' defaultMessage="Log In" />
            </StyledText>
          </StyledButton>
        </Flex>
      </Modal>
    </>
  )
}

export default LoginModal