import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import BottomNavigation from '@mui/material/BottomNavigation'
import { BottomNavigationAction } from '@mui/material'
import LoginModal from 'views/Login'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import useCms from 'contexts/CMS/useCms'
import { useTokenAuth } from 'hooks/useTokenAuth'
import { useCookies } from 'react-cookie'
import DetailsModal from 'views/Profile/components/DetailsModal'
import { styled } from '@nextui-org/react'

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const MobileBottomNav: React.FC = () => {
    const [inAction, setInAction] = useState(false)

    const [cookie, setCookie, removeCookie] = useCookies(["_acc"])
    const query = useQuery();
    const { cms, setCms } = useCms()
    const setCmsRef = useRef(setCms)
    const { tokenValidity } = useTokenAuth()
    const navigate = useNavigate()
    const [bnValue, setBNValue] = useState(0);
    const [loginOpened, { open: openLogin, close: closeLogin }] = useDisclosure(false)
    // const [DetailsOpened, { open: DetailsModal, close: closeDetails }] = useDisclosure(false)
    const navbarToggleRef = useRef(null)
    const [DetailsOpened, { open: openDetails, close: closeDetails }] = useDisclosure(false, {
        onOpen: () => setInAction(true), onClose: () => setInAction(false)
    })
    const [showtab, setShowtab] = useState(0);
    const logout = () => {
        removeCookie("_acc", { path: '/' })
        navigate('/home')
        setShowtab(0);
    }
    const closeMenu = () => {
        navbarToggleRef?.current?.click()
    }
    useEffect(() => {
        const login = query.get('login')
        if (login) {
            openLogin()
        }

        if (cms.title) {
            document.title = cms.title
        }
    }, [cms.title, query, openLogin])


    const handletab = (e) => {
        if (e === 1) {
            setShowtab(e);
            navigate('/home')
        }
        else if (e === 2) {
            setShowtab(e);
            navigate('/verify')
        }
        else if (e === 3) {
            setShowtab(e);
            navigate('/profile')
        }
        else if (e === 4) {
            openDetails()
        }
        

    }

    const CustomIcon = ({ src, label, id, path }) => (
        <button className={showtab === id || window.location.pathname === path ? `change-color` : ''} type='button' onClick={() => handletab(id)} style={{ display: 'flex', alignItems: 'center', height: '100%', background:'none',border: 'none',  borderRadius: "20px", cursor: 'pointer', padding: '15px 25px 15px 25px' }} >
            <img src={src} alt={label} style={{ width: 28, height: 28 }} />
        </button>
    );

    return (
        <> <div className="container">
            <DetailsModal opened={DetailsOpened} onClose={closeDetails} />
            <LoginModal opened={loginOpened} onClose={closeLogin} />
            <BottomNavigation className='bottom-navigation hidewithSizeMoreThan800'
                sx={{ width: '100%', position: "absolute", bottom: 0 , zIndex: 10}}
                value={bnValue}
                onChange={(event, value) => setBNValue(value)}
                style={{ border: '1px solid rgb(189, 189, 189)', borderRadius: 10, height: 70 }}>
                <CustomIcon src="/images/home.svg" label="Home" id={1} path="/home"/>
                <CustomIcon src="/images/clipboard-tick.svg" label="Verify" id={2} path="/verify"/>
                {tokenValidity && <CustomIcon src="/images/user-square.svg" label="Profile" id={3} path="/profile"/>}
                <CustomIcon src="/images/warning-2.svg" label="Info" id={4} path="/info"/>
                {tokenValidity === false && <button type='button' onClick={() => { openLogin(); closeMenu() }} style={{ display: 'flex', alignItems: 'center', height: '100%', background: 'none', border: 'none', cursor: 'pointer', padding: '15px 25px 15px 25px' }} >
                    <img src="/images/login.svg" alt="LogIn" style={{ width: 24, height: 24 }} />
                </button>}
                {tokenValidity && <button type='button' onClick={() => logout()} style={{ display: 'flex', alignItems: 'center', height: '100%', background: 'none', border: 'none', cursor: 'pointer', padding: '15px 25px 15px 25px' }} >
                    <img src="/images/logout.svg" alt="Logout" style={{ width: 24, height: 24 }} />
                </button>}
            </BottomNavigation>
        </div>
        </>
    )
}

export default React.memo(MobileBottomNav);