import { useContext } from 'react'
import { CmsContext } from './Provider'

const useCms = () => {
  const cmsContext = useContext(CmsContext)

  if (cmsContext === undefined) {
    throw new Error('Cms context is undefined')
  }

  return cmsContext
}

export default useCms