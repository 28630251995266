import axios from "axios";
import { useCallback } from "react";

export const useCheckCampaignClaim = () => {
  const handleCheckCampaignClaim = useCallback(async (_token: string) => {
    try {
      const baseUrl = `${process.env.REACT_APP_API_LINK}explorer/certificate/claim`
      const response = await axios.get(baseUrl, {
        headers: {
          'authorization': `${_token}`
        }
      })
      const { data, error, status, msg: message } = await response?.data
      if (error || status !== 200) throw new Error(message)
      return {
        error: false,
        data,
        message
      }
    } catch (e: any) {
      const { message = "" } = e
      return {
        error: true,
        data: {},
        message
      }
    }
  }, [])

  return { onCheckCampaignClaim : handleCheckCampaignClaim }
}

export const useCampaignClaim = () => {
  const handleCampaignClaim = useCallback(async (_certId: string | number | null, _token: string) => {
    try {
      const baseUrl = `${process.env.REACT_APP_API_LINK}explorer/certificate/claim`
      const response = await axios.post(baseUrl, {
        certificate_id: _certId
      }, {
        headers: {
          'authorization': `${_token}`
        }
      })
      const { data, error, status, msg: message } = await response?.data
      if (error || status !== 200) throw new Error(message)
      return {
        error: false,
        data,
        message
      }
    } catch (e: any) {
      const { message = "" } = e
      return {
        error: true,
        data: {},
        message
      }
    }
  }, [])

  return { onCampaignClaim : handleCampaignClaim }
}