import React from 'react'
import { Flex, Modal } from '@mantine/core'
import { FormattedMessage } from 'react-intl'
import { StyledMText } from 'components/GlobalStyle'

interface ProfileImgRedirectModalProps {
  opened: boolean
  onClose: () => void
}

const ProfileImgReminderModal: React.FC<ProfileImgRedirectModalProps> = ({ opened, onClose }) => {
  return (
    <>
      <Modal opened={opened} onClose={onClose} centered closeOnClickOutside
        title={<StyledMText size="20px">
        <FormattedMessage id='profile-pic-reminder-title' defaultMessage="Profile Picture Reminder" />
      </StyledMText>}
        styles={() => ({
          content: {
            borderRadius: '12px', background: 'var(--modalColor)'
          },
          header: {
            marginTop: '12px', background: 'var(--whiteColor)',
            borderBottom: '1px solid #F2F3F4'
          },
          body: {
            background: 'var(--whiteColor)',
            padding: '20px 16px',
            '&:not(:only-child)': { paddingTop: '20px' }
          }
        })}
      >
        <Flex justify="center" align="center" direction="column" gap="1rem" >
          <StyledMText fw="500">
            <FormattedMessage
              id='profile-pic-reminder-subtitle1'
              defaultMessage="It looks like you haven't uploaded any profile picture yet."
            />
          </StyledMText>
          <StyledMText fw="500">
            <FormattedMessage
              id='profile-pic-reminder-subtitle2'
              defaultMessage="Please consider adding one to personalize your profile."
            />
          </StyledMText>
        </Flex>
      </Modal>
    </>
  )
}

export default ProfileImgReminderModal