import React, { useState } from 'react'
import { useNotifications } from 'reapop'
import { FormattedMessage, useIntl } from 'react-intl'
import { Modal, Flex, Textarea, TextInput, createStyles } from '@mantine/core'
import { StyledText, StyledButton } from 'components/GlobalStyle'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const CopyrightLabel = styled.div`
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  padding-top: 5px;
`

interface DetailsModalProps {
  opened: boolean,
  onClose: () => void
}

const useStyles = createStyles(() => ({
  inputStyles: {
    border: "1.2px solid #CED4DA",
    borderRadius: "6px"
  },
  rootStyles: {
    width: "100%"
  },
  labelStyles: {
    color: "var(--textBlack)",
    fontFamily: "var(--fontFamily)",
    fontSize: "15px"
  }
}))

const DetailsModal: React.FC<DetailsModalProps> = ({ opened, onClose }) => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <Modal opened={opened} onClose={onClose} closeOnClickOutside centered
        title={<StyledText fSize="20px">
          <FormattedMessage id='mobile-details' defaultMessage='Info' />
        </StyledText>}
        styles={() => ({
          content: {
            borderRadius: '12px', background: 'var(--modalColor)'
          },
          header: {
            marginTop: '12px', background: 'var(--whiteColor)',
            borderBottom: '1px solid #F2F3F4'
          },
          body: {
            background: 'var(--whiteColor)',
            padding: '20px',
            '&:not(:only-child)': { paddingTop: 0 }
          }
        })}
      >
        <Flex align="center" direction="column" gap="1rem" style={{ paddingTop: '10px' }}>
          <a href='https://www.masverse.com.my/' style={{ backgroundColor: "var(--btnMainColor)", color: "var(--whiteColor)", borderRadius: "200px", width: "100%", height: "auto", minWidth: "48px" }}>
            <p style={{
              display: "flex", alignItems: "center", justifyContent: 'center', zIndex: 'var(--nextui-zIndices-2)'
              , fontSize: "inherit", color: "inherit", fontWeight: "inherit", padding: "6px 14px 6px 14px"
            }}>About Masverse</p>
          </a>

          <a href='https://www.maschain.com/' style={{ backgroundColor: "var(--btnMainColor)", color: "var(--whiteColor)", borderRadius: "200px", width: "100%", height: "auto", minWidth: "48px" }}>
            <p style={{
              display: "flex", alignItems: "center", justifyContent: 'center', zIndex: 'var(--nextui-zIndices-2)'
              , fontSize: "inherit", color: "inherit", fontWeight: "inherit", padding: "6px 14px 6px 14px"
            }}>MasChain</p>
          </a>

          <a href='https://www.masdid.com/merchant-offers' style={{ backgroundColor: "var(--btnMainColor)", color: "var(--whiteColor)", borderRadius: "200px", width: "100%", height: "auto", minWidth: "48px" }}>
            <p style={{
              display: "flex", alignItems: "center", justifyContent: 'center', zIndex: 'var(--nextui-zIndices-2)'
              , fontSize: "inherit", color: "inherit", fontWeight: "inherit", padding: "6px 14px 6px 14px"
            }}>MasDID</p>
          </a>

          <a href='/tnc' style={{ backgroundColor: "var(--btnMainColor)", color: "var(--whiteColor)", borderRadius: "200px", width: "100%", height: "auto", minWidth: "48px" }}>
            <p style={{
              display: "flex", alignItems: "center", justifyContent: 'center', zIndex: 'var(--nextui-zIndices-2)'
              , fontSize: "inherit", color: "inherit", fontWeight: "inherit", padding: "6px 14px 6px 14px"
            }}>Term of Service</p>
          </a>

          <a href='/policy' style={{ backgroundColor: "var(--btnMainColor)", color: "var(--whiteColor)", borderRadius: "200px", width: "100%", height: "auto", minWidth: "48px" }}>
            <p style={{
              display: "flex", alignItems: "center", justifyContent: 'center', zIndex: 'var(--nextui-zIndices-2)'
              , fontSize: "inherit", color: "inherit", fontWeight: "inherit", padding: "6px 14px 6px 14px"
            }}>Privacy Policy</p>
          </a>

          <CopyrightLabel>&copy; {process.env.REACT_APP_PROJECT_NAME} {currentYear}</CopyrightLabel>
        </Flex>
      </Modal>
    </>
  )
}

export default DetailsModal