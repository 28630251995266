import axios from "axios"
import { useCallback } from "react"

// To get profile of an user
export const useGetProfile = (_email: string) => {
  const handleGetProfile = useCallback(async (_token: string) => {
    try {
      if (!_email) throw new Error("Invalid email")
      const baseUrl = `${process.env.REACT_APP_BO_LINK}api/my-edu/get-profile`

      const response = await axios.get(baseUrl, {
        params: {
          email: _email,
          auth_key: _token
        }
      })

      const { data, error, code, message } = await response?.data
      if (error || code !== 200) throw new Error(message)
      return {
        error: false,
        data,
        message: ""
      }
    } catch (e: any) {
      const { message = "" } = e
      return {
        error: true,
        data: {},
        message
      }
    }
  }, [_email])

  return { onGetProfile : handleGetProfile }
}

export const useSubmitProfile = () => {
	const handleSubmitProfile = useCallback(async (formData, frontFile: File, backFile: File, token: string) => {
		try {
      if (!frontFile || !backFile) throw new Error("Files are incomplete.")
			const baseUrl = `${process.env.REACT_APP_BO_LINK}api/my-edu/get-profile`
      // create formdata
      const finalFormData = new FormData()
      Object.keys(formData).forEach((key) => {if (formData[key] != null) finalFormData.append(key, formData[key])})
      finalFormData.append("front_img", frontFile)
      finalFormData.append("back_img", backFile)
      finalFormData.append("auth_key", token)

			const response = await axios.post(baseUrl, finalFormData)

      const { data, error, code, message } = await response?.data
      if (error || code !== 200) throw new Error(message)
      return {
        error: false,
        data,
        message: ""
      }
		} catch (e: any) {
			const { message = "" } = e
      return {
        error: true,
        data: {},
        message
      }
		}
	}, [])

	return { onSubmitProfile : handleSubmitProfile }
}