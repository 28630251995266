import axios from "axios";
import { useCallback } from "react";

export const useCheckout = () => {
  const handleCheckout = useCallback(async (_certId: string | number | null, _token: string) => {
    try {
      const baseUrl = `${process.env.REACT_APP_API_LINK}explorer/certificate/purchase`
      const response = await axios.post(baseUrl, {
        cert_id: _certId,
        // payment_method: "razerpay"
      }, {
        headers: {
          'authorization': `${_token}`
        }
      })
      const { data, error, status, msg: message } = await response?.data
      if (error || status !== 200) throw new Error(message)
      return {
        error: false,
        data,
        message
      }
    } catch (e: any) {
      const { message = "" } = e
      return {
        error: true,
        data: {},
        message
      }
    }
  }, [])

  return { onCheckout: handleCheckout }
}

export const useCheckCertDownload = () => {
  const handleCheckCertDownload = useCallback(async (_refId: string) => {
    try {
      const baseUrl = `${process.env.REACT_APP_API_LINK}explorer/certificate/purchase/check`
      const response = await axios.post(baseUrl, {
        ref_id: _refId,
      }, {
        // headers: {
        //   'authorization': `${_token}`
        // }
      })
      const { data, error, status, msg: message } = await response?.data
      if (error || status !== 200) throw new Error(message)
      return {
        error: false,
        data,
        message
      }
    } catch (e: any) {
      const { message = "" } = e
      return {
        error: true,
        data: {},
        message
      }
    }
  }, [])

  return { onCheckCertDownload: handleCheckCertDownload }
}

// To check on download after user purchase (not first purchase)
export const useCheckPersistCertDownload = () => {
  const handleCheckPersistCertDownload = useCallback(async (_link: string, _token: string) => {
    try {
      if (!_link || !_token) throw new Error("invalid value")
      // const baseUrl = `${process.env.REACT_APP_API_LINK}explorer/certificate/purchase/check/${_refId}`
      const response = await axios.get(_link, {
        headers: {
          'authorization': `${_token}`
        }
      })
      const { data, error, status, msg: message } = await response?.data
      if (error || status !== 200) throw new Error(message)
      return {
        error: false,
        data,
        message
      }
    } catch (e: any) {
      const { message = "" } = e
      return {
        error: true,
        data: {},
        message
      }
    }
  }, [])

  return { onCheckPersistCertDownload: handleCheckPersistCertDownload }
}

// To check payment gateway status (free / on / off)
export const useCheckPaymentGateway = () => {
  const handleCheckPaymentGateway = useCallback(async (_token: string) => {
    try {
      const baseUrl = `${process.env.REACT_APP_API_LINK}explorer/certificate/purchase/status`
      const response = await axios.get(baseUrl, {
        headers: {
          'authorization': `${_token}`
        }
      })
      const { data, error, status, msg: message } = await response?.data
      if (error || status !== 200) throw new Error(message)
      return {
        error: false,
        data,
        message
      }
    } catch (e: any) {
      const { message = "" } = e
      return {
        error: true,
        data: {},
        message
      }
    }
  }, [])

  return { onCheckPaymentGateway : handleCheckPaymentGateway }
}

// To get free cert download link
export const useFreeCheckout = () => {
  const handleFreeCheckout = useCallback(async (_certId: string | number | null, _token: string) => {
    try {
      const baseUrl = `${process.env.REACT_APP_API_LINK}explorer/certificate/purchase/free`
      const response = await axios.post(baseUrl, {
        cert_id: _certId,
      }, {
        headers: {
          'authorization': `${_token}`
        }
      })
      const { data, error, status, msg: message } = await response?.data
      if (error || status !== 200) throw new Error(message)
      return {
        error: false,
        data,
        message
      }
    } catch (e: any) {
      const { message = "" } = e
      return {
        error: true,
        data: {},
        message
      }
    }
  }, [])

  return { onFreeCheckout: handleFreeCheckout }
}