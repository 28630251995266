import React from 'react'
import { NextUIProvider } from '@nextui-org/react'
import { Provider } from 'react-redux'
import { HelmetProvider } from 'react-helmet-async'
import {
  connectorsForWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit'
import {
  metaMaskWallet,
  trustWallet,
  imTokenWallet,
  // walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets'
import { NotificationsProvider } from 'reapop'
import { WagmiConfig, createClient, configureChains, mainnet } from 'wagmi'
import { bsc } from '@wagmi/core/chains'
import { infuraProvider } from 'wagmi/providers/infura'
import { publicProvider } from 'wagmi/providers/public'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { RefreshContextProvider } from 'contexts/RefreshContext'
import { LanguageProvider } from 'contexts/I18nContext'
import { CmsProvider } from 'contexts/CMS/Provider'
import store from 'state'
import { NETWORK_RPC } from 'config/constants/networks'

const { chains, provider } = configureChains(
  [bsc, mainnet],
  [
    infuraProvider({
      apiKey: process.env.REACT_APP_INFURA_KEY,
      priority: 0,
      stallTimeout: 3000,
    }),
    jsonRpcProvider({
      rpc: (chain) => ({
        http: NETWORK_RPC[chain.id],
      }),
      priority: 2,
      stallTimeout: 3000,
    }),
    publicProvider({ priority: 1, stallTimeout: 3000, }),
  ]
)

const connectors = connectorsForWallets([
  {
    groupName: 'Popular',
    wallets: [
      metaMaskWallet({ chains }),
      trustWallet({ chains }),
      imTokenWallet({ chains }),
      // walletConnectWallet({ chains }),
    ],
  },
])

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
})

const Providers: React.FC<any> = ({ children }) => {
  return (
    <WagmiConfig client={wagmiClient}>
      <Provider store={store}>
        <NotificationsProvider>
          <HelmetProvider>
            <LanguageProvider>
              <NextUIProvider>
                <RainbowKitProvider
                  modalSize="compact"
                  chains={chains}
                  appInfo={{
                    learnMoreUrl: 'https://ethereum.org/en/web3/'
                  }}
                >
                  <RefreshContextProvider>
                    <CmsProvider>
                      {children}
                    </CmsProvider>
                  </RefreshContextProvider>
                </RainbowKitProvider>
              </NextUIProvider>
            </LanguageProvider>
          </HelmetProvider>
        </NotificationsProvider>
      </Provider>
    </WagmiConfig>
  )
}

export default Providers