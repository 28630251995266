import React, { useCallback, useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { Loader, Modal, Flex } from '@mantine/core'
import { StyledText, StyledButton } from 'components/GlobalStyle'
import axios from 'axios'

interface PaymentModalProps {
  loading: boolean,
  success: boolean,
  link?: string,
  message?: string
  opened: boolean,
  onClose?: () => void
}

const PaymentModal: React.FC<PaymentModalProps> = ({ loading = true, success = false, link = "", message = "", opened, onClose }) => {
  // const a = document.querySelector('#download-button') as HTMLElement

  const autoDownload = useCallback(() => {
    if (link) {
      axios({ url: link, method: 'GET', responseType: 'blob' })
      .then(response => {
        const regex = /certificates\/(.*)\.pdf/;

        const match = link.match(regex);
        const fileName = match ? [match[1]] : null;

        const href = URL.createObjectURL(response.data)

        const aLink = document.createElement('a')
        aLink.href = href
        aLink.setAttribute('download', `${fileName.length > 0 ? fileName[0] : "cert"}.pdf`)
        aLink.click()
        // aLink.remove()

        // clean up
        URL.revokeObjectURL(href)
      })
      // a.click()
    }
  }, [link])
  useEffect(() => {
    autoDownload()
  }, [autoDownload])

  return (
    <>
      <Modal opened={opened} onClose={onClose} closeOnClickOutside centered withCloseButton={false}
        styles={() => ({
          content: {
            borderRadius: '12px', background: 'var(--modalColor)'
          },
          header: {
            background: 'var(--whiteColor)',
            borderBottom: '1px solid #F2F3F4'
          },
          body: {
            marginTop: '12px',
            background: 'var(--whiteColor)',
            padding: '20px 16px',
            '&:not(:only-child)': { paddingTop: 0 }
          }
        })}
      >
        <Flex align="center" direction="column" gap="1rem">
          {loading ? <Flex align="center" mih="330px">
            <Loader size="xl" style={{ maxWidth: "250px" }} />
          </Flex>
          :
          success ? <>
            <img src="/images/modal/payment-success.png" alt="payment-success" style={{ maxWidth: "64px" }} />
            <StyledText fWeight="bold" fSize="24px">
                <FormattedMessage id='payment-title-success' defaultMessage="Payment successful!" />
            </StyledText>
            <StyledText fWeight="normal" fSize="1rem" colorCode='var(--textGrey)'>
              <FormattedMessage id='payment-subtitle1' defaultMessage="Your download will start shortly, please wait..." />
            </StyledText>
            <StyledText colorCode="var(--textGrey)" fWeight="normal" fSize="1rem" marginTop="3px">
              <FormattedMessage
                id='payment-subtitle2'
                defaultMessage="If the file is not downloading, <styledSpan>click here</styledSpan>"
                values={{
                  styledSpan: chunks => <span style={{ color: "var(--textBlue)" }}>
                    <a id='download-button' href={link || '!#' } target='_blank' rel='noreferrer'>
                      {chunks}
                    </a>
                  </span>
                }}
              />
            </StyledText>
          </>
          :
          <>
            <img src="/images/modal/payment-fail.png" alt="payment-fail"  style={{ maxWidth: "64px" }} />
            <StyledText fWeight="bold" fSize="24px">
              {message ?
              <FormattedMessage id='payment-title-failed2' defaultMessage="Download failed." />
              :
              <FormattedMessage id='payment-title-failed' defaultMessage="Payment failed." />}
            </StyledText>
            {message ? <StyledText fWeight="normal" fSize="1rem" colorCode="var(--textGrey)">
              {message}
            </StyledText>
            :
            <StyledText fWeight="normal" fSize="1rem" colorCode="var(--textGrey)">
              <FormattedMessage id='payment-subtitle3' defaultMessage="Please check your payment information and try again." />
            </StyledText>}
          </>
          }
          {!loading && <StyledButton borderRadius="200px" width="auto" minWidth="150px" minHeight="44px" margin="1rem 0 0"
            onClick={onClose} >
            <StyledText colorCode='var(--whiteColor)' transform='uppercase'>
              <FormattedMessage
                id='btn-label-done'
                defaultMessage="Done"
              />
            </StyledText>
          </StyledButton>}
        </Flex>
      </Modal>
    </>
  )
}

export default PaymentModal