import { useCallback } from 'react'
import axios from 'axios'

export const useDynamicInstInfo = () => {
  const handleDynamicInstInfo = useCallback(async (_origin, _langCode: string) => {
    try {
      const baseUrl = `${process.env.REACT_APP_API_LINK}institution/info`

      const response = await axios.get(baseUrl, {
        params: {
          origin: _origin,
          languageCode: _langCode
        }
      })

      const { data, error, status, msg: message } = await response?.data
      if (error || status !== 200) throw new Error(message)
      return {
        error: false,
        data,
        message
      }
    } catch (e: any) {
      const { message = "" } = e
      return {
        error: true,
        data: {},
        message
      }
    }
  }, [])

  return { onDynamicInstInfo : handleDynamicInstInfo }
}