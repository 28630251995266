import React, { useState } from 'react'
import { useNotifications } from 'reapop'
import { FormattedMessage, useIntl } from 'react-intl'
import { Modal, Flex, Textarea, TextInput, createStyles } from '@mantine/core'
import * as Yup from 'yup'
import { useForm, yupResolver } from '@mantine/form'
import { StyledText, StyledButton } from 'components/GlobalStyle'
import { useTokenAuth } from 'hooks/useTokenAuth'
import { useSubmitTicket } from '../hooks/useUserCertListing'

interface EmailModalProps {
  opened: boolean,
  onClose: () => void
}

const useStyles = createStyles(() => ({
  inputStyles: {
    border: "1.2px solid #CED4DA",
    borderRadius: "6px"
  },
  rootStyles: {
    width: "100%"
  },
  labelStyles: {
    color: "var(--textBlack)",
    fontFamily: "var(--fontFamily)",
    fontSize: "15px"
  }
}))

const EmailModal: React.FC<EmailModalProps> = ({ opened, onClose }) => {
  const intl = useIntl()
  const { classes } = useStyles()
  const { notify } = useNotifications()

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(intl.formatMessage({
      id: "form-error-req-name", defaultMessage: "Name is required"
    })),
    email: Yup.string().required(intl.formatMessage({
      id: "form-error-req-email", defaultMessage: "Email is required"
    })),
    subject: Yup.string().required(intl.formatMessage({
      id: "form-error-req-subject", defaultMessage: "Subject is required"
    })),
    message: Yup.string().required(intl.formatMessage({
      id: "form-error-req-content", defaultMessage: "Content is required"
    })),
  })

  const form = useForm({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      message: ""
    },
    validate: yupResolver(validationSchema)
  })

  const [inAction, setInAction] = useState(false)
  const { tokenValidity, content } = useTokenAuth()
  const { onSubmitTicket : handleSubmitTicket } = useSubmitTicket()

  if (tokenValidity === false) onClose()

  const handleSubmit = async () => {
    setInAction(true)
    try {
      const response = await handleSubmitTicket(form.values)
      if (response.error) throw new Error(response.message)
      notify(intl.formatMessage({
        id: 'toast-success-msg-submit-ticket',
        defaultMessage: 'Your ticket has been successfully submitted.'
      }), 'error', {
        title: intl.formatMessage({
          id: 'toast-success-title',
          defaultMessage: 'Success'
        })
      })
      form.reset()
      onClose()
    } catch (e: any) {
      const { message = "" } = e
      notify(intl.formatMessage({
        id: 'toast-error-msg-general',
        defaultMessage: 'Please try again. Message: {message}',
      }, { message }), 'error', {
        title: intl.formatMessage({
          id: 'toast-error-title',
          defaultMessage: 'Error'
        })
      })
    }
    setInAction(false)
  }

  return (
    <>
      <Modal opened={opened} onClose={onClose} closeOnClickOutside centered
        title={<StyledText fSize="20px">
          <FormattedMessage id='submit-ticket-title' defaultMessage="Submit a Ticket" />
        </StyledText>}
        styles={() => ({
          content: {
            borderRadius: '12px', background: 'var(--modalColor)'
          },
          header: {
            marginTop: '12px', background: 'var(--whiteColor)',
            borderBottom: '1px solid #F2F3F4'
          },
          body: {
            background: 'var(--whiteColor)',
            padding: '20px',
            '&:not(:only-child)': { paddingTop: 0 }
          }
        })}
      >
        <Flex align="center" direction="column" gap="1rem">
          <TextInput classNames={{
            input: classes.inputStyles, root: classes.rootStyles, label: classes.labelStyles
            }} id="ticket-name-input" label={<FormattedMessage
              id="form-label-name" defaultMessage="Name"
            />}
            {...form.getInputProps("name")}
          />
          <TextInput classNames={{
              input: classes.inputStyles, root: classes.rootStyles, label: classes.labelStyles
            }} id="ticket-email-input" label={intl.formatMessage({
              id: "form-label-email", defaultMessage: "Email Address"
            })}
            {...form.getInputProps("email")}
          />
          <TextInput classNames={{
              input: classes.inputStyles, root: classes.rootStyles, label: classes.labelStyles
            }} id="ticket-subject-input" label={intl.formatMessage({
              id: "form-label-subject", defaultMessage: "Subject"
            })}
            {...form.getInputProps("subject")}
          />
          <Textarea classNames={{
              input: classes.inputStyles, root: classes.rootStyles, label: classes.labelStyles
            }} id="ticket-content-input" label={intl.formatMessage({
              id: "form-label-content", defaultMessage: "Content"
            })} autosize minRows={4} maxRows={4}
            {...form.getInputProps("message")}
          />
          <StyledButton borderRadius="200px" width="auto" minWidth='110px' maxWidth="200px" height='auto'
            minHeight='44px' onClick={form.onSubmit(handleSubmit)} disabled={inAction} >
            <StyledText colorCode='var(--whiteColor)'>
              <FormattedMessage
                id='btn-label-submit'
                defaultMessage="Submit"
              />
            </StyledText>
          </StyledButton>
        </Flex>
      </Modal>
    </>
  )
}

export default EmailModal