import React, { lazy, useLayoutEffect } from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate
} from 'react-router-dom'
import BigNumber from 'bignumber.js'
import Menu from 'components/Menu'
import MenuV2 from 'components/MenuV2'
import MobileBottomNav from 'components/MobileBottomNavigation'
import Footer from 'components/Footer'
import PageLoader from 'components/Loader/PageLoader'
import { usePollBlockNumber } from 'state/block/hooks'
import { useUpdateNetwork } from 'state/network/hooks'
import SuspenseWithChunkError from 'components/SuspenseWithChunkError'
import { templateId } from 'utils'
import Profile from './views/Profile'

const Home = lazy(() => import('./views/Home'))
// const Profile = () => import('./views/Profile')
const CertificateDetails = lazy(() => import('./views/CertificateDetails'))
const VerifyCertificate = lazy(() => import('./views/VerifyCertificate'))
const TncPolicy = lazy(() => import('./views/TncPolicy'))
const Onboarding = lazy(() => import('./views/Onboarding'))
const AlumniKyc = lazy(() => import('./views/AlumniKyc'))
const Search = lazy(() => import('./views/Search'))
const CheckIn = lazy(() => import('./views/CheckIn'))
const NotFound = lazy(() => import('./views/NotFound'))
const Mint = lazy(() => import('./views/Mint'))
// const Referral = lazy(() => import('./views/Referral'))

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const RemoveTrailingSlash = ({ ...rest }) => {
  const location = useLocation()

  // If the last character of the url is '/'
  if (location.pathname.match('/.*/$')) {
    return <Navigate replace {...rest} to={{
      pathname: location.pathname.replace(/\/+$/, ""),
      search: location.search
    }} />
  }

  return null
}

const App: React.FC = () => {
  usePollBlockNumber()
  useUpdateNetwork()

  // dynamic import css based on template
  useLayoutEffect(() => {
    async function importLibrary() {
      await import(`./css/Temp${templateId}.css`)
    }
    importLibrary()
  }, [])

  return (
    <BrowserRouter>
      <div className="master-container setheight">
        <MenuV2 />
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <RemoveTrailingSlash />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/search" element={<Search />} />
            <Route path="/profile/:userId?" element={<Profile />} />
            <Route path="/alumni-kyc" element={<AlumniKyc />} />
            <Route path="/certificate/:certificateId" element={<CertificateDetails />} />
            <Route path="/onboarding" element={<Onboarding />} />
            <Route path="/verify" element={<VerifyCertificate />} />

            <Route path="/about" element={<TncPolicy cmsType="about" />} />
            <Route path="/policy" element={<TncPolicy cmsType="privacy" />} />
            <Route path="/tnc" element={<TncPolicy cmsType="tnc" />} />

            <Route path='checkin/:masdidId' element={<CheckIn />} />

            {/* 404 */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </SuspenseWithChunkError>
        <MobileBottomNav />
      </div>
      <Footer />
    </BrowserRouter>
  )
}

export default React.memo(App)
