import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import useRefresh from 'hooks/useRefresh'
// import { useCheckAuth } from 'views/Profile/hooks/useUserCertListing'

// To check on JWT validity
export const useTokenAuth = () => {
  const { slowRefresh } = useRefresh()
  const [cookie] = useCookies(['_acc'])
  const [tokenValidity, setTokenValidity] = useState(false)

  // const { onCheckAuth : handleCheckAuth } = useCheckAuth()
  
  useEffect(() => {
    // const checkAuth = async () => {
    //   const result = await handleCheckAuth(cookie._acc.token)
    // }
    if (cookie._acc) {
      if (cookie._acc.exp * 1000 < Date.now()) {
        setTokenValidity(false)
      } else {
        setTokenValidity(true)
      }
    } else {
      setTokenValidity(false)
    }
  }, [cookie, slowRefresh])

  return { tokenValidity, content: (cookie._acc) ? cookie._acc : null }
}