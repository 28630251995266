import axios, { AxiosRequestConfig } from "axios";
import React, { useCallback } from "react";

export const useUserCertListing = () => {
  const handleUserCertListing = useCallback(async (_userId: string, _page = 1, _limit = 9, _token: string, _category?: string | null) => {
    try {
      if (!_userId) throw new Error("Invalid user id")
      const baseUrl = `${process.env.REACT_APP_API_LINK}explorer/certificates`

      const options: AxiosRequestConfig = _token ? { headers: {
        'authorization': `${_token}`
      }} : null
      const response = await axios.get(baseUrl, {
        params: {
          page: _page,
          limit: _limit,
          user_id: _userId,
          ...(_category ? { category: _category } : {})
        },
        ...options
      })

      // eslint-disable-next-line camelcase
      const { data, error, status, msg: message, max_page, total_count } = await response?.data
      if (error || status !== 200) throw new Error(message)
      const { certificates = [] } = data
      return {
        data: data ? certificates : [],
        // eslint-disable-next-line camelcase
        max: max_page,
        // eslint-disable-next-line camelcase
        total: total_count
      }
    } catch (e: any) {
      return {
        data: [],
        max: 0,
        total: 0
      }
    }
  }, [])

  return { onUserCertListing : handleUserCertListing }
}

export const useUserCertListingMobileVersion = () => {
  const handleUserCertListingMobileVersion = useCallback(async (_userId: string, _token: string, _category?: string | null) => {
    try {
      if (!_userId) throw new Error("Invalid user id")
      const baseUrl = `${process.env.REACT_APP_API_LINK}explorer/certificates-mobile`

      const options: AxiosRequestConfig = _token ? { headers: {
        'authorization': `${_token}`
      }} : null
      const response = await axios.get(baseUrl, {
        params: {
          user_id: _userId,
          ...(_category ? { category: _category } : {})
        },
        ...options
      })

      // eslint-disable-next-line camelcase
      const { data, error, status, msg: message, max_page, total_count } = await response?.data
      if (error || status !== 200) throw new Error(message)
      const { certificates = [] } = data
      return {
        data: data ? certificates : [],
        // eslint-disable-next-line camelcase
        max: max_page,
        // eslint-disable-next-line camelcase
        total: total_count
      }
    } catch (e: any) {
      return {
        data: [],
        max: 0,
        total: 0
      }
    }
  }, [])

  return { onuseUserCertListingMobileVersion : handleUserCertListingMobileVersion }
}

export const useUserMasdidCheckInLogListing = () => {
  const handleUserCheckInLogListing = useCallback(async (_userId: string, _page = 1, _limit = 10,_token: string) => {
    try {
      if (!_userId) throw new Error("Invalid user id")
      const baseUrl = `${process.env.REACT_APP_API_LINK}explorer/checkIn`
      const response = await axios.get(baseUrl, {
        params: {
          page: _page,
          limit: _limit,
          user_id: _userId,
        },
        headers: {
          'authorization': `${_token}`
        }

      })

      // eslint-disable-next-line camelcase
      const { data, error, status, msg: message, max_page, total_count } = await response?.data
      if (error || status !== 200) throw new Error(message)
        const checkinLog = [];
      checkinLog.push(data)

      return {
        data: data ? checkinLog[0] : [],
        // eslint-disable-next-line camelcase
        max: max_page,
        // eslint-disable-next-line camelcase
        total: total_count
      }
    } catch (e: any) {
      return {
        data: [],
        max: 0,
        total: 0
      }
    }
  }, [])

  return { onUserCheckInLogListing : handleUserCheckInLogListing }
}

// user profile without auth
export const useGetUserDetails = () => {
  const handleGetUserDetails = useCallback(async (_userId: string | number | null) => {
    if (!_userId) throw new Error("Invalid certificate ID")
    try {
      const baseUrl = `${process.env.REACT_APP_API_LINK}explorer/user/${_userId}/details`
      const response = await axios.get(baseUrl)
      const { data, error, status, msg: message } = await response?.data
      if (error || status !== 200) throw new Error(message)
      return {
        error: false,
        data,
        message
      }
    } catch (e: any) {
      const { message = "" } = e
      return {
        error: true,
        data: {},
        message
      }
    }
  }, [])

  return { onGetUserDetails : handleGetUserDetails }
}

// user profile with auth
export const useCheckAuth = () => {
  const handleCheckAuth = useCallback(async (_token: string) => {
    try {
      const baseUrl = `${process.env.REACT_APP_API_LINK}explorer/user/checkAuth`
      const response = await axios.get(baseUrl, {
        headers: {
          'authorization': `${_token}`
        }
      })
      const { data, error, status, msg: message } = await response?.data
      if (error || status !== 200) throw new Error(message)
      return {
        error: false,
        data,
        message
      }
    } catch (e: any) {
      const { message = "" } = e
      return {
        error: true,
        data: {},
        message
      }
    }
  }, [])

  return { onCheckAuth : handleCheckAuth }
}

// To check on kyc status
export const useCheckKyc = () => {
  const handleCheckKyc = useCallback(async (_email: string, _token: string) => {
    try {
      const baseUrl = `${process.env.REACT_APP_BO_LINK}api/my-edu/get-kyc-status`
      const response = await axios.get(baseUrl, {
        params: {
          email: _email,
          auth_key: _token
        }
      })
      const { data, error, code, message } = await response?.data
      if (error || code !== 200) throw new Error(message)
      return {
        error: false,
        data,
        message
      }
    } catch (e: any) {
      const { message = "" } = e
      return {
        error: true,
        data: {},
        message
      }
    }
  }, [])

  return { onCheckKyc : handleCheckKyc }
}

// for submitting ticket
export const useSubmitTicket = () => {
  const handleSubmitTicket = useCallback(async (_data) => {
    try {
      const baseUrl = `${process.env.REACT_APP_API_LINK}explorer/ticket`
      const response = await axios.post(baseUrl, {
        ..._data
      })
      const { data, error, status, msg: message } = await response?.data
      if (error || status !== 200) throw new Error(message)
      return {
        error: false,
        data,
        message
      }
    } catch (e: any) {
      const { message = "" } = e
      return {
        error: true,
        data: {},
        message
      }
    }
  }, [])

  return { onSubmitTicket : handleSubmitTicket }
}

// To update cert privacy setting
export const useSetCertPrivacy = () => {
  const handleSetCertPrivacy = useCallback(async (
    _data: { certificate_id: number, mode: string }, _token: string
    ) => {
    try {
      const baseUrl = `${process.env.REACT_APP_API_LINK}explorer/certificate/mode`
      const response = await axios.post(baseUrl, {
        ..._data
      }, {
        headers: {
          'authorization': `${_token}`
        }
      })
      const { data, error, status, msg: message } = await response?.data
      if (error || status !== 200) throw new Error(message)
      return {
        error: false,
        data,
        message
      }
    } catch (e: any) {
      const { message = "" } = e
      return {
        error: true,
        data: {},
        message
      }
    }
  }, [])

  return { onSetCertPrivacy : handleSetCertPrivacy }
}

// To upload profile picture
export const useUploadProfilePic = () => {
  const handleUploadProfilePic = useCallback(async (_image: File, _token: string) => {
    try {
      if (!_image) throw new Error("File is incomplete")
      const baseUrl = `${process.env.REACT_APP_API_LINK}explorer/user/profile_pic`
      const formData = new FormData()
      formData.append('profile', _image)
      const response = await axios.post(baseUrl, formData, {
        headers: {
          'authorization': `${_token}`
        }
      })
      const { data, error, status, msg: message } = await response?.data
      if (error || status !== 200) throw new Error(message)
      return {
        error: false,
        data,
        message
      }
    } catch (e: any) {
      const { message = "" } = e
      return {
        error: true,
        data: {},
        message
      }
    }
  }, [])

  return { onUploadProfilePic: handleUploadProfilePic }
}

// To request new JWT
export const useRequestJwt = () => {
  const handleRequestJwt = useCallback(async (_token: string) => {
    try {
      const baseUrl = `${process.env.REACT_APP_API_LINK}explorer/user/jwt`
      const response = await axios.post(baseUrl, null, {
        headers: {
          'authorization': `${_token}`
        }
      })
      const { data, error, status, msg: message } = await response?.data
      if (error || status !== 200) throw new Error(message)
      return {
        error: false,
        data,
        message
      }
    } catch (e: any) {
      const { message = "" } = e
      return {
        error: true,
        data: {},
        message
      }
    }
  }, [])

  return { onRequestJwt: handleRequestJwt }
}

// To get cert categories
export const useGetCertCategories = () => {
  const handleGetCertCategories = useCallback(async () => {
    try {
      const baseUrl = `${process.env.REACT_APP_API_LINK}explorer/categories`
      const response = await axios.get(baseUrl)
      const { data, error, status, msg: message } = await response?.data
      if (error || status !== 200) throw new Error(message)
      return {
        error: false,
        data: data && data.categories ? data.categories : [],
        message
      }
    } catch (e: any) {
      const { message = "" } = e
      return {
        error: true,
        data: [],
        message
      }
    }
  }, [])

  return { onGetCertCategories: handleGetCertCategories }
}