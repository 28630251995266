import React from 'react'
import classnames from 'classnames'
import { usePagination, DOTS } from 'hooks/usePagination'

const Pagination: React.FC<{
    onPageChange; totalCount; siblingCount?: number; currentPage; pageSize; className; useWhite
}> = ({ onPageChange, totalCount, siblingCount = 1, currentPage, pageSize, className, useWhite }) => {
    const paginationRange = usePagination({
        currentPage, totalCount, siblingCount, pageSize
    })

    if (currentPage === 0 || paginationRange.length < 1) {
        return null
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    }
    const onPrevious = () => {
        onPageChange(currentPage - 1);
    }

    const lastPage = paginationRange[paginationRange.length - 1]
    return (
        <ul className="pagination-container" style={{ marginTop: '1rem', paddingLeft: '0px' }}>
            <li className={classnames(useWhite ? 'pagination-item-white' : 'pagination-item', { disabled: currentPage === 1 })} role="presentation" onClick={onPrevious} >
                <div className="arrow left" role="button" tabIndex={0} aria-label="Previous" onKeyPress={onPrevious} />
                {/* <div className="arrow left" role="button" tabIndex={0} aria-label="Previous" onKeyPress={onPrevious} onClick={onPrevious} /> */}
            </li>
            {paginationRange.map(pageNumber => {
                if (pageNumber === DOTS) {
                    return <li className={useWhite ? "pagination-item-white dots" : "pagination-item dots"}>&#8230;</li>
                }

                return (
                    <li className={classnames(useWhite ? 'pagination-item-white' : 'pagination-item', { selected: pageNumber === currentPage })} role="presentation" onClick={() => onPageChange(pageNumber)} >
                        <div role="button" tabIndex={0} onKeyPress={() => false} style={{ fontFamily: 'var(--fontFamily)' }} >{pageNumber}</div>
                        {/* <div role="button" tabIndex={0} onKeyPress={() => false} onClick={() => onPageChange(pageNumber)}>{pageNumber}</div> */}
                    </li>
                )
            })}
            <li className={classnames(useWhite ? 'pagination-item-white' : 'pagination-item', { disabled: currentPage === lastPage })} role="presentation" onClick={onNext} >
                <div className="arrow right" role="button" tabIndex={0} aria-label="Next" onKeyPress={onNext} />
                {/* <div className="arrow right" role="button" tabIndex={0} aria-label="Next" onKeyPress={onNext} onClick={onNext} /> */}
            </li>
        </ul>
    )
}

export default Pagination