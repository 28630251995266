export enum paymentGatewayStatusType {
  ON = "on",
  OFF = "off",
}

export enum paymentGatewayStatusByCertType {
  PAID = "paid",
  OFF = "off",
  FREE = "free"
}

export const withdrawModalKeyVersus = {
  bankName: "bank_name",
  accountHolder: "account_holder",
  accountNo: "bank_account_number"
}

export const withdrawModalEditKeyVersus = {
  bankName: "bank_name",
  accountHolder: "bank_account_name",
  accountNo: "bank_account_no"
}