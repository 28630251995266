import React,{useState} from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Divider } from '@mantine/core'
import useCms from 'contexts/CMS/useCms'

const FooterPanel = styled.div`
  display: flex;
  background-color: white;
  color: var(--textGrey);
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 30px;
  gap: 20px;
`

const CopyrightLabel = styled.div`
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  padding-top: 5px;
`

const MenuPanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: var(--textGrey);
  gap: 20px;
  font-size: 16px;
  justify-content: center;
`

const Background = styled.div`
  background-color: var(--whiteColor);

  .container .menu-panel {
    @media only screen and (max-width: 495px) {
      flex-direction: column;
      row-gap: 16px;
    }
  }
`

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const [isCheckIn, setIsCheckIn] = useState(false)
  const { cms } = useCms()

  return (
    <Background className="footer-layout">
      {isCheckIn === false &&
      <div className="container">
        <FooterPanel className="footer-panel hidewithSize">
          <MenuPanel className="menu-panel hidewithSize">
            {cms && cms.website && <>
              <a className="footer-nav-text" href={cms.website} target='_blank' rel='noopener noreferrer'>
                {cms.name}
              </a>
              <Divider orientation="vertical" />
            </>}
            <Link to="https://www.masverse.com.my/" className="footer-nav-text">
              <FormattedMessage id='footer-about-us' defaultMessage='About Masverse' />
            </Link>
            <Divider orientation="vertical" />
            <Link to="https://www.maschain.com/" className="footer-nav-text">
              <FormattedMessage id='footer-maschain' defaultMessage='MasChain' />
            </Link>
            <Divider orientation="vertical" />
            <Link to="/tnc" className="footer-nav-text">
              <FormattedMessage id='footer-about-tnc' defaultMessage='Term of Service' />
            </Link>
            <Divider orientation="vertical" />
            <Link to="/policy" className="footer-nav-text">
              <FormattedMessage id='footer-privacy' defaultMessage='Privacy Policy' />
            </Link>
          </MenuPanel>

          <CopyrightLabel>&copy; {process.env.REACT_APP_PROJECT_NAME} {currentYear}</CopyrightLabel>
        </FooterPanel>
      </div>
}
    </Background>
  );
}

export default Footer
