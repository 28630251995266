import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { has } from 'lodash'
import { FormattedMessage, useIntl } from 'react-intl'
import { Loader, Modal, TextInput, createStyles, Flex } from '@mantine/core'
import { useNotifications } from 'reapop'
import * as Yup from 'yup'
import BigNumber from 'bignumber.js'
import { useForm, yupResolver } from '@mantine/form'
import { StyledText, StyledButton } from 'components/GlobalStyle'
import { useTokenAuth } from 'hooks/useTokenAuth'
import { useGetProfile } from 'views/AlumniKyc/hooks/useAlumniKyc'
import { withdrawModalEditKeyVersus, withdrawModalKeyVersus } from '../constant'
import { useWithdraw } from '../hooks/useWithdrawal'

interface WithdrawModalProps {
  opened: boolean,
  onClose: () => void
  withdrawAmount: string
  minClaimAmount: BigNumber | null
}

const useStyles = createStyles(() => ({
  inputStyles: {
    border: "1.2px solid #CED4DA",
    borderRadius: "6px"
  },
  rootStyles: {
    width: "100%"
  },
  labelStyles: {
    color: "var(--textBlack)",
    fontFamily: "var(--fontFamily)",
    fontSize: "15px"
  }
}))

const WithdrawModal: React.FC<WithdrawModalProps> = ({ opened, onClose, withdrawAmount, minClaimAmount }) => {
  const intl = useIntl()
  const { classes } = useStyles()
  const { notify } = useNotifications()

  const validationSchema = Yup.object().shape({
    bankName: Yup.string().required(intl.formatMessage({
      id: "form-error-req-bank-name", defaultMessage: "Bank name is required"
    })),
    accountHolder: Yup.string().required(intl.formatMessage({
      id: "form-error-req-acc-name", defaultMessage: "Account holder name is required"
    })),
    accountNo: Yup.string().required(intl.formatMessage({
      id: "form-error-req-acc-no", defaultMessage: "Account number is required"
    })),
  })

  const formInitialValues = useMemo(() => ({
    bankName: "",
    accountHolder: "",
    accountNo: ""
  }), [])
  const form = useForm({
    initialValues: formInitialValues,
    validate: yupResolver(validationSchema)
  })
  const formRef = useRef(form)

  const [inAction, setInAction] = useState(false)
  const [loading, setLoading] = useState(true)

  const { tokenValidity, content } = useTokenAuth()
  const { onWithdraw : handleWithdraw } = useWithdraw()
  const { onGetProfile : handleGetProfile } = useGetProfile(has(content, 'email') ? content.email : null)

  if (tokenValidity === false) onClose()

  // get profile
  const getProfile = useCallback(async () => {
    const result = await handleGetProfile(has(content, "token") ? content.token : null)
    if (result.error === false && Object.keys(result.data).length > 0) {
      // generate new object from api, with additional filter applied at the beginning to return necessary data
      const newObjectArr = Object.keys(result.data).filter(singleKey => 
        Object.keys(withdrawModalEditKeyVersus).some(rkey => withdrawModalEditKeyVersus[rkey] === singleKey)
      )
      .map(key => {
        const newKey = Object.keys(withdrawModalEditKeyVersus).find(refKey => withdrawModalEditKeyVersus[refKey] === key)
        return { [newKey]: result.data[key] }
      })
      const newDataObject = newObjectArr.reduce(() => Object.assign({}, ...newObjectArr), formInitialValues)

      const finalNewDataObject = { ...newDataObject }
      formRef.current.setValues(finalNewDataObject)
    }
  }, [handleGetProfile, formInitialValues, content])

  const handleSubmit = async () => {
    setInAction(true)
    try {
      // generate new object for api usage
      // const keyException = ["idFrontUpload", "idBackUpload"]
      const newObjectArr: { [key: string]: any }[] = Object.keys(form.values)
      // remove unwanted upload file key as it will be appended to formadata
      // .filter(singleKey => keyException.includes(singleKey) === false)
      .map(key => {
        const newKey = withdrawModalKeyVersus[key] || key  // replace keys
        return { [newKey]: form.values[key] }
      })
      const newDataObject = newObjectArr.reduce(() => Object.assign({}, ...newObjectArr))

      const response = await handleWithdraw(newDataObject, has(content, 'token') ? content.token : null)
      if (response.error) throw new Error(response.message)
      notify(intl.formatMessage({
        id: 'toast-success-msg-submit-withdraw',
        defaultMessage: 'Your withdrawal request has been successfully submitted. We will review your information and send you a notification via email once the process is complete.'
      }), 'success', {
        title: intl.formatMessage({
          id: 'toast-success-title',
          defaultMessage: 'Success'
        })
      })
      onClose()
    } catch (e: any) {
      const { message = "" } = e
      if (message.includes("User denied message signature")) {
        notify(intl.formatMessage({
          id: 'toast-error-msg-tx-denied',
          defaultMessage: 'You have denied the transaction confirmation.'
        }), 'error', {
          title: intl.formatMessage({
            id: 'toast-error-title',
            defaultMessage: 'Error'
          })
        })
      } else if (message.includes("has already been taken.")) {
        notify(intl.formatMessage({
          id: 'toast-error-msg-email-duplicate',
          defaultMessage: 'Email has already been taken.'
        }), 'error', {
          title: intl.formatMessage({
            id: 'toast-error-title',
            defaultMessage: 'Error'
          })
        })
      } else if (message.includes("Mismatch signature")) {
        notify(intl.formatMessage({
          id: 'toast-error-msg-sig-mismatch',
          defaultMessage: 'Signature mismatch.'
        }), 'error', {
          title: intl.formatMessage({
            id: 'toast-error-title',
            defaultMessage: 'Error'
          })
        })
      } else {
        notify(intl.formatMessage({
          id: 'toast-error-msg-general',
          defaultMessage: 'Please try again. Message: {message}',
        }, { message }), 'error', {
          title: intl.formatMessage({
            id: 'toast-error-title',
            defaultMessage: 'Error'
          })
        })
      }
    }
    setInAction(false)
  }

  useEffect(() => {
    getProfile().finally(() => setLoading(false))
  }, [getProfile])

  return (
    <>
      <Modal opened={opened} onClose={onClose} closeOnClickOutside centered
        title={<StyledText fSize="20px">
          <FormattedMessage id='withdraw-req-title' defaultMessage="Withdrawal Request" />
        </StyledText>}
        styles={() => ({
          content: {
            borderRadius: '12px', background: 'var(--modalColor)'
          },
          header: {
            marginTop: '12px', background: 'var(--whiteColor)',
            borderBottom: '1px solid #F2F3F4'
          },
          body: {
            background: 'var(--whiteColor)',
            padding: '20px 16px',
            '&:not(:only-child)': { paddingTop: 0 }
          }
        })}
      >
        <Flex align="center" direction="column" gap="1rem">
          {loading ? <Flex align="center" mih="330px">
            <Loader size="xl" style={{ maxWidth: "250px" }} />
          </Flex>
          :
          <>
            <StyledText fWeight="bold" fSize="20px">
              <FormattedMessage id="label-amount" defaultMessage="Amount" />: MYR {withdrawAmount}
            </StyledText>

            <TextInput classNames={{
              input: classes.inputStyles, root: classes.rootStyles, label: classes.labelStyles
              }} id="withdraw-bank-name-input" label={<FormattedMessage
                id="kyc-form-label-bank-name" defaultMessage="Bank Name"
              />}
              {...form.getInputProps("bankName")}
            />
            <TextInput classNames={{
                input: classes.inputStyles, root: classes.rootStyles, label: classes.labelStyles
              }} id="withdraw-account-holder-input" label={intl.formatMessage({
                id: "kyc-form-label-acc-name", defaultMessage: "Account Holder Name"
              })}
              {...form.getInputProps("accountHolder")}
            />
            <TextInput classNames={{
                input: classes.inputStyles, root: classes.rootStyles, label: classes.labelStyles
              }} id="withdraw-account-no-input" label={intl.formatMessage({
                id: "kyc-form-label-acc-no", defaultMessage: "Account Number"
              })}
              {...form.getInputProps("accountNo")}
            />
            {minClaimAmount && minClaimAmount.isNaN() === false && new BigNumber(withdrawAmount).gte(minClaimAmount) ?
            <StyledButton borderRadius="200px" width="auto" minWidth='110px' maxWidth="200px" minHeight='44px'
              onClick={form.onSubmit(handleSubmit)} disabled={inAction || !minClaimAmount} >
              <StyledText colorCode='var(--whiteColor)'>
                <FormattedMessage
                  id='btn-label-submit'
                  defaultMessage="Submit"
                />
              </StyledText>
            </StyledButton>
            :
            <StyledText fWeight="bold" fSize="13px" fFamily='var(--fontFamily)' textAlign="center">
              <FormattedMessage
                id='withdraw-req-subtitle1'
                defaultMessage="Minimum withdrawal amount need to more than MYR {minClaimAmount}"
                values={{
                  minClaimAmount: minClaimAmount ? minClaimAmount.toFixed(2) : '-'
                }}
              />
            </StyledText>
            }
          </>
          }
        </Flex>
      </Modal>
    </>
  )
}

export default WithdrawModal