import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Modal, Flex } from '@mantine/core'
import { useTimeout } from '@mantine/hooks'
import { StyledText, StyledButton } from 'components/GlobalStyle'
import { Player } from '@lottiefiles/react-lottie-player'
import giftBoxOpenJson from 'assets/lottie/gift-box-open.json'

interface RewardModalProps {
  opened: boolean,
  onClose: () => void
  rewardAmount: string
  onClaimReward: () => void
  inAction: boolean
}

const RewardModal: React.FC<RewardModalProps> = ({ opened, onClose, rewardAmount, onClaimReward, inAction }) => {
  const [isChosen, setIsChosen] = useState(false)
  const { start: resetChosen } = useTimeout(() => setIsChosen(false), 600)

  const actionOnClose = () => {
    onClose()
    resetChosen()
  }

  const actionOnChoose = () => {
    onClaimReward()
    setIsChosen(true)
  }

  return (
    <>
      <Modal opened={opened} onClose={() => actionOnClose()} closeOnClickOutside centered withCloseButton={false}
        styles={() => ({
          content: {
            borderRadius: '12px', background: 'var(--modalColor)'
          },
          header: {
            background: 'var(--whiteColor)',
            borderBottom: '1px solid #F2F3F4'
          },
          body: {
            marginTop: '12px',
            background: 'var(--whiteColor)',
            padding: '20px 16px',
            '&:not(:only-child)': { paddingTop: 0 }
          }
        })}
      >
        {isChosen ? <Flex align="center" direction="column" gap="1rem">
          <Player src={giftBoxOpenJson} autoplay loop style={{ maxWidth: '350px' }} />
          <StyledText fWeight="bold" fSize="25px" fFamily='var(--fontFamilyBold)'>
            <FormattedMessage id='reward-title' defaultMessage="Congratulation!" />
          </StyledText>
          <Flex align="center" gap="4px">
            <StyledText fWeight="bold" fSize="1rem" fFamily='var(--fontFamily)'>
              <FormattedMessage
                id='reward-subtitle1'
                defaultMessage="You have successfully earned MYR {rewardAmount}"
                values={{ rewardAmount }}
              />
            </StyledText>
            {/* <img src="/images/usdc.svg" alt="usdc" style={{ maxWidth: "24px" }} /> */}
          </Flex>
          <StyledButton borderRadius="200px" width="auto" minWidth="150px" minHeight="44px"  margin="1rem 0 0"
            onClick={() => actionOnClose()} >
            <StyledText colorCode='var(--whiteColor)' transform='uppercase'>
              <FormattedMessage
                id='btn-label-done'
                defaultMessage="Done"
              />
            </StyledText>
          </StyledButton>
        </Flex>
        :
        <Flex align="center" direction="column" gap="1rem">
          <Flex wrap="wrap" justify="center" m="1rem auto" gap="1rem">
            {[...Array(5)].map((value, index) => {
              const imgId = `box-${index}`
              return <div key={imgId} role="button" tabIndex={inAction ? -1 : 0} onClick={() => actionOnChoose()} onKeyDown={() => console.log()}
                style={{ textAlign: "center", flex: "0 0 30.3333%", pointerEvents: inAction ? 'none' : 'unset' }}
              >
                <img id={`${index + 1}`} key={imgId} src="/images/modal/giftbox-static.png" alt="giftbox-static" style={{ maxWidth: "100px" }} />
              </div>
            })}
          </Flex>
          <StyledText fWeight="bold" fSize="20px" fFamily='var(--fontFamilyBold)' textAlign="center">
            <FormattedMessage id='reward-subtitle2' defaultMessage="Pick your favourite box from the bunch!" />
          </StyledText>
        </Flex>
        }
      </Modal>
    </>
  )
}

export default RewardModal