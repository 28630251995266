import React, { useState, ReactText } from 'react'

type ContextData = {
  [key: string]: ReactText
}

interface cmsData {
  apply_black_layer: boolean;
  name: string;
  ssm_reg_no: string;
  subdomain_url: string;
  logo: string;
  nft_contract_address: string;
  website: string;
  color_theme: string;
  background_image: string;
  cms: ContextData[],
  title: string;
}

// tagline
// sub_tagline
// tnc
// privacy_policy
export enum cmsDynamicKey {
  TAGLINE = 'tagline',
  SUBTAGLINE = 'sub_tagline',
  TNC = 'tnc',
  PP = 'privacy_policy'
}

interface CmsContextProps {
  cms: cmsData
  setCms: (data: cmsData) => void
}

export const CmsContext = React.createContext<CmsContextProps | undefined>(undefined)

export const initialData: cmsData = {
  name: null, ssm_reg_no: null, subdomain_url: null, logo: null,
  nft_contract_address: null, website: null, color_theme: null,
  background_image: null, cms: [], title: null, apply_black_layer: true
}

export const CmsProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
  const [cmsData, setCmsData] = useState<CmsContextProps['cms']>(initialData)

  const setCms = (data: cmsData) => setCmsData(data)

  return (
    <CmsContext.Provider value={{ cms: cmsData, setCms }}>
      { children }
    </CmsContext.Provider>
  )
}