import React, { useMemo, useState } from 'react'
import { Dropdown } from '@nextui-org/react'
import { Flex } from '@mantine/core'
import { useTranslation } from 'contexts/I18nContext'
import { languageList } from 'config/constants/languages'

const LangSelector: React.FC = () => {
  const { setLanguage, currentLanguage } = useTranslation()
  const [selected, setSelected] = useState(new Set([currentLanguage.locale]))
  const selectedValue = useMemo(() =>
    Array.from(selected),
    [selected]
  )

  return (
    <Dropdown>
      <Dropdown.Button bordered css={{
        color: 'var(--textGrey)', borderColor: 'var(--textGrey)',
        paddingLeft: '12px', paddingRight: '12px', borderRadius: '200px',
        border: '2px solid #F2F3F4'
      }}
      >
        <Flex align='center' wrap='nowrap'>
          <img src='/images/globe.svg' alt='globe' style={{ fill: 'var(--textGrey)' }} />
          {languageList.find(l => l.locale === selectedValue.toString())?.code?.toUpperCase()}
        </Flex>
      </Dropdown.Button>
      <Dropdown.Menu
        disallowEmptySelection
        selectionMode="single"
        selectedKeys={selected}
        onSelectionChange={(la) => {
          setSelected(la as Set<string>)
          const newLocale = Object.values(la)[0]
          setLanguage(languageList.find(l => l.locale === newLocale.toString()))
        }}
      >
        {languageList.map((la) => (
          <Dropdown.Item key={la.locale}>{la.language}</Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default LangSelector