import { defineMessage } from 'react-intl'

export enum kycStatus {
  PENDING = 'pending',
  SUBMITTED = 'submitted',
  APPROVED = 'approved',
  REJECTED = 'rejected'
}

export enum actionTypeValue {
  SUBMIT = "submit",
  UPDATE = "update",
  VIEW = "view",
}

export const kycConfig = {
  [kycStatus.PENDING]: {
    text: defineMessage({
      id: 'onboarding-subtitle2', defaultMessage: "Please submit your information for KYC."
    }),
    color: "#E3F6FF"
  },
  [kycStatus.SUBMITTED]: {
    text: defineMessage({
      id: 'kyc-config-status1', defaultMessage: "Your KYC has been submitted for review."
    }),
    color: "#E3F6FF"
  },
  [kycStatus.APPROVED]: {
    text: defineMessage({
      id: 'kyc-config-status2', defaultMessage: ""
    }),
    color: ""
  },
  [kycStatus.REJECTED]: {
    text: defineMessage({
      id: 'kyc-config-status3', defaultMessage: "Your KYC is being rejected."
    }),
    color: "#F8D7DA"
  },
}

export const kycCountrySelectKey = {
  value: 'country_name',
  label: 'country_name',
  countrycode: 'phone_code'
}

export const kycEditKeyVersus = {
  email: "email",
  firstName: "first_name",
  lastName: "last_name",
  DOB: "dob",
  gender: "gender",
  country: "country_name",
  countryCode: "phone_code",
  contact: "phone_number",
  bankName: "bank_name",
  accountHolder: "bank_account_name",
  accountNo: "bank_account_no"
}