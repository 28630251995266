import React, { useMemo } from 'react'

export const DOTS = '...'

const range = (start, end) => {
    const length = end - start + 1
    return Array.from({ length }, (_, idx) => idx + start)
}

export const usePagination = ({
    totalCount,
    pageSize = 10,
    siblingCount = 1,
    currentPage
}) => {
    const paginationRange = useMemo(() => {
        let returnArr = []
        const totalPageCount = Math.ceil(totalCount / pageSize)

        // page count = siblingCount + 1st page + last page + current page _ 2 DOTS
        const totalPageNumbers = siblingCount + 5

        // if the pages number < page number wanted to show, return range of [1...totalPageCount]
        if (totalPageNumbers >= totalPageCount) {
            returnArr = range(1, totalPageCount);
            return returnArr
        }

        const leftSiblingIndex = Math.max(currentPage - siblingCount, 1)
        const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount)

        // Not showing dots if there is only 1 position left
        const shouldShowLeftDots = leftSiblingIndex > 2
        const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2

        const firstPageIndex = 1
        const lastPageIndex = totalPageCount

        if (!shouldShowLeftDots && shouldShowRightDots) {
            const leftItemCount = 3+ 2 * siblingCount
            const leftRange = range(1, leftItemCount)
            returnArr = [...leftRange, DOTS, totalPageCount]
            return returnArr
        }

        if (shouldShowLeftDots && !shouldShowRightDots) {
            const rightItemCount = 3 + 2 * siblingCount
            const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount)
            returnArr = [firstPageIndex, DOTS, ...rightRange]
            return returnArr
        }

        if (shouldShowLeftDots && shouldShowRightDots) {
            const middleRange = range(leftSiblingIndex, rightSiblingIndex)
            returnArr = [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex]
            return returnArr
        }
        return returnArr
    }, [currentPage, pageSize, siblingCount, totalCount])

    return paginationRange
}