import axios from "axios";
import { useCallback } from "react";

export const useCheckWalletBalance = () => {
  const handleCheckWalletBalance = useCallback(async (_token: string) => {
    try {
      const baseUrl = `${process.env.REACT_APP_API_LINK}explorer/wallet`
      const response = await axios.get(baseUrl, {
        headers: {
          'authorization': `${_token}`
        }
      })
      const { data, error, status, msg: message } = await response?.data
      if (error || status !== 200) throw new Error(message)
      return {
        error: false,
        data,
        message
      }
    } catch (e: any) {
      const { message = "" } = e
      return {
        error: true,
        data: {},
        message
      }
    }
  }, [])

  return { onCheckWalletBalance : handleCheckWalletBalance }
}

export const useWithdraw = () => {
  const handleWithdraw = useCallback(async (_data, _token: string) => {
    try {
      const baseUrl = `${process.env.REACT_APP_API_LINK}explorer/wallet/withdraw`
      const response = await axios.post(baseUrl, {
        ..._data
      }, {
        headers: {
          'authorization': `${_token}`
        }
      })
      const { data, error, status, msg: message } = await response?.data
      if (error || status !== 200) throw new Error(message)
      return {
        error: false,
        data,
        message
      }
    } catch (e: any) {
      const { message = "" } = e
      return {
        error: true,
        data: {},
        message
      }
    }
  }, [])

  return { onWithdraw : handleWithdraw }
}

export const useUserWithdrawalListing = () => {
  const handleUserWithdrawalListing = useCallback(async (_token: string, _page = 1, _limit = 10) => {
    try {
      const baseUrl = `${process.env.REACT_APP_API_LINK}explorer/wallet/withdraw`

      const response = await axios.get(baseUrl, {
        params: {
          page: _page,
          limit: _limit,
        },
        headers: {
          'authorization': `${_token}`
        }
      })

      // eslint-disable-next-line camelcase
      const { data, error, status, msg: message, max_page, total_count } = await response?.data
      if (error || status !== 200) throw new Error(message)
      // eslint-disable-next-line camelcase
      const { withdrawal_requests = [] } = data
      return {
        // eslint-disable-next-line camelcase
        data: data ? withdrawal_requests : [],
        // eslint-disable-next-line camelcase
        max: max_page,
        // eslint-disable-next-line camelcase
        total: total_count
      }
    } catch (e: any) {
      return {
        data: [],
        max: 0,
        total: 0
      }
    }
  }, [])

  return { onUserWithdrawalListing : handleUserWithdrawalListing }
}