import React from 'react'
import styled, { css } from 'styled-components'
import BigNumber from 'bignumber.js'
import { Flex, Switch } from '@mantine/core'
import { campaignClaimInfoType, certDownloadInfoType, listingObj } from 'config/constants/types'
import { StyledText, Divider, StyledButton } from 'components/GlobalStyle'
import { Link, useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { useTokenAuth } from 'hooks/useTokenAuth'
import { paymentGatewayStatusByCertType, paymentGatewayStatusType } from '../constant'

interface CertItemProps {
  certInfo: listingObj
  paymentGatewayStatus: paymentGatewayStatusType
  onPaidDownload: (downloadLink: string) => void
  certDownloadInfo: certDownloadInfoType
  checkoutPrint: (certId: number) => void
  onFreeCheckout: (certId: number) => void
  campaignClaimInfo: campaignClaimInfoType
  onGiftClaim: (certId: number) => void
  inAction: boolean
  setCertPrivacy: (certId: number, mode: boolean) => void
}

const ItemWrapper = styled.div`
  box-sizing: border-box;
  border: 1px solid #F2F3F4;
  border-radius: 8px;
  padding: 20px;
  min-width: 350px;
  max-width: 370px;
`

const CertItem: React.FC<CertItemProps> = ({
  certInfo, paymentGatewayStatus, onPaidDownload, certDownloadInfo,
  checkoutPrint, onFreeCheckout, campaignClaimInfo, onGiftClaim, inAction, setCertPrivacy
}) => {
  const { tokenValidity, content } = useTokenAuth()
  const navigate = useNavigate()

  const mobileView = 768;

  function onNavigate() {
    if (window.innerWidth <= mobileView) {
      navigate(`/certificate/${certInfo.tx_id}`)
    }
  }




  const dateFormat: Intl.DateTimeFormatOptions = {
    day: 'numeric', // numeric, 2-digit
    month: 'short', // numeric, 2-digit, long, short, narrow
    year: 'numeric',
  }
  // To format the graduation date
  const getFormattedDateStr = (date: string) => {
    if (!date || date === "") return "-"
    const gDate = new Date(date)
    const dateStr = gDate.toLocaleString("en-MY", dateFormat)
    return dateStr
  }

  const getstandardDatetimeStr = (date: string) => {
    if (!date || date === "") return "-"
    const newdate = new Date(date);
    const formatter = new Intl.DateTimeFormat('en-MY', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    const formattedTime = formatter.format(newdate);
    return formattedTime
  }

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        aria-hidden="true"
        onClick={() => onNavigate()}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          flexGrow: 0,
          flexShrink: 0,
          width: 'auto',
          position: "relative",
          gap: 16,
          padding: 16,
          background: "#fff",
        }}
        className='setPaddingMobile'
      >
        <div style={{
          display: "flex",
          width: '100%',
          overflow: "hidden",
          gap: 20
        }}>
          { certInfo.icon ? <img src={certInfo.icon} alt='logo' style={{ width: '70px', height: '60px' }} /> : ""}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              alignSelf: "stretch",
              gap: 16,
              flex: "1 1 0%",
            }}
            className='removegap'
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                alignSelf: "stretch",
                position: "relative",
                gap: 8,
                overflow: "hidden",
              }}
            >
              <p
                style={{
                  alignSelf: "stretch",
                  flexGrow: 0,
                  flexShrink: 0,
                  width: 355,
                  fontSize: 16,
                  fontWeight: 700,
                  textAlign: "left",
                  color: "#232323",
                }}
                className='hidedetails'
              >
                {certInfo.institution_name}
                <StyledText fWeight='normal' fSize='12px' style={{ opacity: 0.5 }} >
                  <FormattedMessage id='cert-item-subtitle1' defaultMessage='This Certitificate is present to' />
                </StyledText>
              </p>
              <div style={{display: 'flex'}}>


                <svg
                  width={18}
                  height={18}
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ flexGrow: 0, flexShrink: 0, width: 18, height: 18, position: "relative" }}
                  preserveAspectRatio="xMidYMid meet" className='hidedetails'
                >
                  <path
                    d="M7.94998 3.11252V4.5H6.84C5.6325 4.5 5.02501 5.10749 5.02501 6.31499V13.5H3.11252C2.04002 13.5 1.5 12.96 1.5 11.8875V3.11252C1.5 2.04002 2.04002 1.5 3.11252 1.5H6.33751C7.41001 1.5 7.94998 2.04002 7.94998 3.11252Z"
                    fill="#835FE9"
                  />
                  <path
                    opacity="0.4"
                    d="M13.0275 6.31499V14.685C13.0275 15.8925 12.4275 16.5 11.22 16.5H6.84001C5.63251 16.5 5.02502 15.8925 5.02502 14.685V6.31499C5.02502 5.10749 5.63251 4.5 6.84001 4.5H11.22C12.4275 4.5 13.0275 5.10749 13.0275 6.31499Z"
                    fill="#835FE9"
                  />
                  <path
                    d="M16.5 3.11252V11.8875C16.5 12.96 15.96 13.5 14.8875 13.5H13.0275V6.31499C13.0275 5.10749 12.4275 4.5 11.22 4.5H10.05V3.11252C10.05 2.04002 10.59 1.5 11.6625 1.5H14.8875C15.96 1.5 16.5 2.04002 16.5 3.11252Z"
                    fill="#835FE9"
                  />
                  <path
                    d="M10.5 8.8125H7.5C7.1925 8.8125 6.9375 8.5575 6.9375 8.25C6.9375 7.9425 7.1925 7.6875 7.5 7.6875H10.5C10.8075 7.6875 11.0625 7.9425 11.0625 8.25C11.0625 8.5575 10.8075 8.8125 10.5 8.8125Z"
                    fill="#835FE9"
                  />
                  <path
                    d="M10.5 11.0625H7.5C7.1925 11.0625 6.9375 10.8075 6.9375 10.5C6.9375 10.1925 7.1925 9.9375 7.5 9.9375H10.5C10.8075 9.9375 11.0625 10.1925 11.0625 10.5C11.0625 10.8075 10.8075 11.0625 10.5 11.0625Z"
                    fill="#835FE9"
                  />
                  <path
                    d="M9.5625 14.25V16.5H8.4375V14.25C8.4375 13.9425 8.6925 13.6875 9 13.6875C9.3075 13.6875 9.5625 13.9425 9.5625 14.25Z"
                    fill="#835FE9"
                  />
                </svg>
                <p
                  style={{
                    fontSize: 12,
                    fontWeight: 600,
                    textAlign: "left",
                    color: "#232323",
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitLineClamp: 1,
                    wordWrap: 'break-word',
                    wordBreak: 'break-all',
                    WebkitBoxOrient: 'vertical',
                  }}
                  className='cert-name-list-font-mobile'
                >
                  {certInfo.cert_name}
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                alignSelf: "stretch",
                flexGrow: 1,
                flexShrink: 0,
                gap: 8,
                width: '100%'
              }}
            >

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexGrow: 0,
                  flexShrink: 0,
                  position: "relative",
                  gap: 6,
                  width: '100%'
                }}
                className='hidewithSizeMoreThan800'
              >

                <p
                  style={{
                    flexGrow: 0,
                    flexShrink: 0,
                    fontSize: 12,
                    fontWeight: 600,
                    textAlign: "left",
                  }}
                  className='cert-date-list-font-mobile'
                >
                  {getFormattedDateStr(certInfo.created_at)} {getstandardDatetimeStr(certInfo.created_at)}
                </p>
                <div style={{ display: 'none', zIndex: 0 }} className='showdetails'>
                  {(paymentGatewayStatus === paymentGatewayStatusType.ON) ?
                    certInfo.download_link ?
                      <StyledButton style={{ backgroundColor: 'transparent', float: 'right' }} onClick={() => onPaidDownload(certInfo.download_link)} >
                        <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.4" d="M20.5 10.19H17.61C15.24 10.19 13.31 8.26 13.31 5.89V3C13.31 2.45 12.86 2 12.31 2H8.07C4.99 2 2.5 4 2.5 7.57V16.43C2.5 20 4.99 22 8.07 22H15.93C19.01 22 21.5 20 21.5 16.43V11.19C21.5 10.64 21.05 10.19 20.5 10.19Z" fill="#835FE9" />
                          <path d="M15.8 2.20999C15.39 1.79999 14.68 2.07999 14.68 2.64999V6.13999C14.68 7.59999 15.92 8.80999 17.43 8.80999C18.38 8.81999 19.7 8.81999 20.83 8.81999C21.4 8.81999 21.7 8.14999 21.3 7.74999C19.86 6.29999 17.28 3.68999 15.8 2.20999Z" fill="#835FE9" />
                          <path d="M12.28 14.72C11.99 14.43 11.51 14.43 11.22 14.72L10.5 15.44V11.25C10.5 10.84 10.16 10.5 9.75 10.5C9.34 10.5 9 10.84 9 11.25V15.44L8.28 14.72C7.99 14.43 7.51 14.43 7.22 14.72C6.93 15.01 6.93 15.49 7.22 15.78L9.22 17.78C9.23 17.79 9.24 17.79 9.24 17.8C9.3 17.86 9.38 17.91 9.46 17.95C9.56 17.98 9.65 18 9.75 18C9.85 18 9.94 17.98 10.03 17.94C10.12 17.9 10.2 17.85 10.28 17.78L12.28 15.78C12.57 15.49 12.57 15.01 12.28 14.72Z" fill="#835FE9" />
                        </svg>
                      </StyledButton>
                      :
                      ((certInfo.status === paymentGatewayStatusByCertType.PAID) ?
                        ((certDownloadInfo.cert_id === certInfo.certificate_id && certDownloadInfo.link) ?
                          <Link to={{ pathname: certDownloadInfo.link || "!#" }} target="_blank">
                            <StyledButton style={{ backgroundColor: 'transparent', float: 'right' }} >
                              <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.4" d="M20.5 10.19H17.61C15.24 10.19 13.31 8.26 13.31 5.89V3C13.31 2.45 12.86 2 12.31 2H8.07C4.99 2 2.5 4 2.5 7.57V16.43C2.5 20 4.99 22 8.07 22H15.93C19.01 22 21.5 20 21.5 16.43V11.19C21.5 10.64 21.05 10.19 20.5 10.19Z" fill="#835FE9" />
                                <path d="M15.8 2.20999C15.39 1.79999 14.68 2.07999 14.68 2.64999V6.13999C14.68 7.59999 15.92 8.80999 17.43 8.80999C18.38 8.81999 19.7 8.81999 20.83 8.81999C21.4 8.81999 21.7 8.14999 21.3 7.74999C19.86 6.29999 17.28 3.68999 15.8 2.20999Z" fill="#835FE9" />
                                <path d="M12.28 14.72C11.99 14.43 11.51 14.43 11.22 14.72L10.5 15.44V11.25C10.5 10.84 10.16 10.5 9.75 10.5C9.34 10.5 9 10.84 9 11.25V15.44L8.28 14.72C7.99 14.43 7.51 14.43 7.22 14.72C6.93 15.01 6.93 15.49 7.22 15.78L9.22 17.78C9.23 17.79 9.24 17.79 9.24 17.8C9.3 17.86 9.38 17.91 9.46 17.95C9.56 17.98 9.65 18 9.75 18C9.85 18 9.94 17.98 10.03 17.94C10.12 17.9 10.2 17.85 10.28 17.78L12.28 15.78C12.57 15.49 12.57 15.01 12.28 14.72Z" fill="#835FE9" />
                              </svg>
                            </StyledButton>
                          </Link>
                          :
                          <StyledButton style={{ backgroundColor: 'transparent', float: 'right' }} onClick={() => checkoutPrint(certInfo.certificate_id)} >
                            <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.4" d="M20.5 10.19H17.61C15.24 10.19 13.31 8.26 13.31 5.89V3C13.31 2.45 12.86 2 12.31 2H8.07C4.99 2 2.5 4 2.5 7.57V16.43C2.5 20 4.99 22 8.07 22H15.93C19.01 22 21.5 20 21.5 16.43V11.19C21.5 10.64 21.05 10.19 20.5 10.19Z" fill="#835FE9" />
                              <path d="M15.8 2.20999C15.39 1.79999 14.68 2.07999 14.68 2.64999V6.13999C14.68 7.59999 15.92 8.80999 17.43 8.80999C18.38 8.81999 19.7 8.81999 20.83 8.81999C21.4 8.81999 21.7 8.14999 21.3 7.74999C19.86 6.29999 17.28 3.68999 15.8 2.20999Z" fill="#835FE9" />
                              <path d="M12.28 14.72C11.99 14.43 11.51 14.43 11.22 14.72L10.5 15.44V11.25C10.5 10.84 10.16 10.5 9.75 10.5C9.34 10.5 9 10.84 9 11.25V15.44L8.28 14.72C7.99 14.43 7.51 14.43 7.22 14.72C6.93 15.01 6.93 15.49 7.22 15.78L9.22 17.78C9.23 17.79 9.24 17.79 9.24 17.8C9.3 17.86 9.38 17.91 9.46 17.95C9.56 17.98 9.65 18 9.75 18C9.85 18 9.94 17.98 10.03 17.94C10.12 17.9 10.2 17.85 10.28 17.78L12.28 15.78C12.57 15.49 12.57 15.01 12.28 14.72Z" fill="#835FE9" />
                            </svg>
                          </StyledButton>
                        )
                        :
                        certInfo.status === paymentGatewayStatusByCertType.FREE ?
                          <StyledButton style={{ backgroundColor: 'transparent', float: 'right' }} onClick={() => onFreeCheckout(certInfo.certificate_id)} >
                            <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.4" d="M20.5 10.19H17.61C15.24 10.19 13.31 8.26 13.31 5.89V3C13.31 2.45 12.86 2 12.31 2H8.07C4.99 2 2.5 4 2.5 7.57V16.43C2.5 20 4.99 22 8.07 22H15.93C19.01 22 21.5 20 21.5 16.43V11.19C21.5 10.64 21.05 10.19 20.5 10.19Z" fill="#835FE9" />
                              <path d="M15.8 2.20999C15.39 1.79999 14.68 2.07999 14.68 2.64999V6.13999C14.68 7.59999 15.92 8.80999 17.43 8.80999C18.38 8.81999 19.7 8.81999 20.83 8.81999C21.4 8.81999 21.7 8.14999 21.3 7.74999C19.86 6.29999 17.28 3.68999 15.8 2.20999Z" fill="#835FE9" />
                              <path d="M12.28 14.72C11.99 14.43 11.51 14.43 11.22 14.72L10.5 15.44V11.25C10.5 10.84 10.16 10.5 9.75 10.5C9.34 10.5 9 10.84 9 11.25V15.44L8.28 14.72C7.99 14.43 7.51 14.43 7.22 14.72C6.93 15.01 6.93 15.49 7.22 15.78L9.22 17.78C9.23 17.79 9.24 17.79 9.24 17.8C9.3 17.86 9.38 17.91 9.46 17.95C9.56 17.98 9.65 18 9.75 18C9.85 18 9.94 17.98 10.03 17.94C10.12 17.9 10.2 17.85 10.28 17.78L12.28 15.78C12.57 15.49 12.57 15.01 12.28 14.72Z" fill="#835FE9" />
                            </svg>
                          </StyledButton>
                          :
                          null
                      )
                    :
                    null
                  }
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexGrow: 0,
                  flexShrink: 0,
                  position: "relative",
                  gap: 6,
                }}
                className='hidedetails'
              >
                <svg
                  width={18}
                  height={18}
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ flexGrow: 0, flexShrink: 0, width: 18, height: 18 }}
                  preserveAspectRatio="xMidYMid meet"
                >
                  <path
                    d="M12.5624 2.67V1.5C12.5624 1.1925 12.3074 0.9375 11.9999 0.9375C11.6924 0.9375 11.4374 1.1925 11.4374 1.5V2.625H6.56243V1.5C6.56243 1.1925 6.30743 0.9375 5.99993 0.9375C5.69243 0.9375 5.43743 1.1925 5.43743 1.5V2.67C3.41243 2.8575 2.42993 4.065 2.27993 5.8575C2.26493 6.075 2.44493 6.255 2.65493 6.255H15.3449C15.5624 6.255 15.7424 6.0675 15.7199 5.8575C15.5699 4.065 14.5874 2.8575 12.5624 2.67Z"
                    fill="#835FE9"
                  />
                  <path
                    opacity="0.4"
                    d="M15 7.38C15.4125 7.38 15.75 7.7175 15.75 8.13V12.75C15.75 15 14.625 16.5 12 16.5H6C3.375 16.5 2.25 15 2.25 12.75V8.13C2.25 7.7175 2.5875 7.38 3 7.38H15Z"
                    fill="#835FE9"
                  />
                  <path
                    d="M6.375 11.25C6.18 11.25 5.985 11.1675 5.8425 11.0325C5.7075 10.89 5.625 10.695 5.625 10.5C5.625 10.305 5.7075 10.11 5.8425 9.9675C6.0525 9.7575 6.3825 9.68999 6.66 9.80999C6.7575 9.84749 6.84 9.9 6.9075 9.9675C7.0425 10.11 7.125 10.305 7.125 10.5C7.125 10.695 7.0425 10.89 6.9075 11.0325C6.765 11.1675 6.57 11.25 6.375 11.25Z"
                    fill="#835FE9"
                  />
                  <path
                    d="M9 11.25C8.805 11.25 8.61 11.1675 8.4675 11.0325C8.3325 10.89 8.25 10.695 8.25 10.5C8.25 10.305 8.3325 10.11 8.4675 9.9675C8.535 9.9 8.6175 9.84749 8.715 9.80999C8.9925 9.68999 9.3225 9.7575 9.5325 9.9675C9.6675 10.11 9.75 10.305 9.75 10.5C9.75 10.695 9.6675 10.89 9.5325 11.0325C9.495 11.0625 9.4575 11.0925 9.42 11.1225C9.375 11.1525 9.33 11.175 9.285 11.19C9.24 11.2125 9.195 11.2275 9.15 11.235C9.0975 11.2425 9.0525 11.25 9 11.25Z"
                    fill="#835FE9"
                  />
                  <path
                    d="M11.625 11.25C11.43 11.25 11.235 11.1675 11.0925 11.0325C10.9575 10.89 10.875 10.695 10.875 10.5C10.875 10.305 10.9575 10.11 11.0925 9.96752C11.1675 9.90002 11.2425 9.8475 11.34 9.81C11.475 9.75 11.625 9.735 11.775 9.765C11.82 9.7725 11.865 9.7875 11.91 9.81C11.955 9.825 12 9.84752 12.045 9.87752C12.0825 9.90752 12.12 9.93752 12.1575 9.96752C12.2925 10.11 12.375 10.305 12.375 10.5C12.375 10.695 12.2925 10.89 12.1575 11.0325C12.12 11.0625 12.0825 11.0925 12.045 11.1225C12 11.1525 11.955 11.175 11.91 11.19C11.865 11.2125 11.82 11.2275 11.775 11.235C11.7225 11.2425 11.67 11.25 11.625 11.25Z"
                    fill="#835FE9"
                  />
                  <path
                    d="M6.375 13.875C6.2775 13.875 6.18 13.8525 6.09 13.815C5.9925 13.7775 5.9175 13.725 5.8425 13.6575C5.7075 13.515 5.625 13.32 5.625 13.125C5.625 12.93 5.7075 12.735 5.8425 12.5925C5.9175 12.525 5.9925 12.4725 6.09 12.435C6.225 12.375 6.375 12.36 6.525 12.39C6.57 12.3975 6.615 12.4125 6.66 12.435C6.705 12.45 6.75 12.4725 6.795 12.5025C6.8325 12.5325 6.87 12.5625 6.9075 12.5925C7.0425 12.735 7.125 12.93 7.125 13.125C7.125 13.32 7.0425 13.515 6.9075 13.6575C6.87 13.6875 6.8325 13.725 6.795 13.7475C6.75 13.7775 6.705 13.8 6.66 13.815C6.615 13.8375 6.57 13.8525 6.525 13.86C6.4725 13.8675 6.4275 13.875 6.375 13.875Z"
                    fill="#835FE9"
                  />
                  <path
                    d="M9 13.875C8.805 13.875 8.61 13.7925 8.4675 13.6575C8.3325 13.515 8.25 13.32 8.25 13.125C8.25 12.93 8.3325 12.735 8.4675 12.5925C8.745 12.315 9.255 12.315 9.5325 12.5925C9.6675 12.735 9.75 12.93 9.75 13.125C9.75 13.32 9.6675 13.515 9.5325 13.6575C9.39 13.7925 9.195 13.875 9 13.875Z"
                    fill="#835FE9"
                  />
                  <path
                    d="M11.625 13.875C11.43 13.875 11.235 13.7925 11.0925 13.6575C10.9575 13.515 10.875 13.32 10.875 13.125C10.875 12.93 10.9575 12.735 11.0925 12.5925C11.37 12.315 11.88 12.315 12.1575 12.5925C12.2925 12.735 12.375 12.93 12.375 13.125C12.375 13.32 12.2925 13.515 12.1575 13.6575C12.015 13.7925 11.82 13.875 11.625 13.875Z"
                    fill="#835FE9"
                  />
                </svg>
                <p
                  style={{
                    flexGrow: 0,
                    flexShrink: 0,
                    fontSize: 12,
                    fontWeight: 600,
                    textAlign: "left",
                    color: "#232323",
                  }}
                >
                  {getFormattedDateStr(certInfo.created_at)}
                </p>
              </div>
            </div>
          </div>
        </div>
        <Flex wrap='wrap' gap={5} w='100%' mt={5} style={{ flex: '1 0 auto' }} className='hidedetails'>
          {certInfo.categories.map(category =>
            <StyledText fSize='12px' textAlign='center' style={{
              borderRadius: '12px', backgroundColor: category.color_code || 'var(--modalColor)',
              padding: '6px 7px', color: '#835fe9', height: '25px'
            }}
            >
              {category.category_name}
            </StyledText>
          )}
        </Flex>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            alignSelf: "stretch",
            flexGrow: 0,
            flexShrink: 0,
            position: "relative",
            gap: 16,
          }}
          className='hidedetails'
        >
          <svg
            width={355}
            height={2}
            viewBox="0 0 355 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ alignSelf: "stretch", flexGrow: 0, flexShrink: 0 }}
            preserveAspectRatio="none"
          >
            <path d="M0 1H355" stroke="#D9D9D9" strokeWidth="0.5" />
          </svg>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              alignSelf: "stretch",
              flexGrow: 0,
              flexShrink: 0,
            }}
          >
            <Flex align='center' columnGap={5} >
              <Switch defaultChecked={certInfo.is_private === false} disabled={inAction} color='teal' checked={!certInfo.is_private}
                onChange={(event) => setCertPrivacy(certInfo.certificate_id, event.target.checked)} size='xs'
                styles={{
                  body: { justifyContent: 'center' },
                  track: { 'input:disabled:checked+&': { backgroundColor: '#12b886', borderColor: '#12b886' }, height: '14px' },
                  thumb: { height: '10px', width: '10px', 'input:checked+*>&': { left: 'calc(100% - 10px - 0.0625rem)' } }
                }}
              />
              <StyledText colorCode='var(--textGrey)' fSize="12px" fWeight='bold' textAlign="center" lineHeight={1} >
                {certInfo.is_private ? <FormattedMessage id='label-private' defaultMessage='Private' />
                  :
                  <FormattedMessage id='label-public' defaultMessage='Public' />
                }
              </StyledText>

            </Flex>
            <Link to={`/certificate/${certInfo.tx_id}`}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexGrow: 0,
                  flexShrink: 0,
                  position: "relative",
                  gap: 6,
                }}
              >
                <p
                  style={{
                    flexGrow: 0,
                    flexShrink: 0,
                    fontSize: 14,
                    fontWeight: 700,
                    textAlign: "left",
                    color: "#6f65e8",
                  }}
                >
                  Read more
                </p>
                <svg
                  width={14}
                  height={14}
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ flexGrow: 0, flexShrink: 0, width: 14, height: 14, position: "relative" }}
                  preserveAspectRatio="xMidYMid meet"
                >
                  <path
                    d="M5.19751 11.62L9.00084 7.81667C9.45001 7.3675 9.45001 6.6325 9.00084 6.18334L5.19751 2.38"
                    stroke="#6F65E8"
                    strokeWidth="1.5"
                    strokeMiterlimit={10}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </Link>
          </div>

          {(paymentGatewayStatus === paymentGatewayStatusType.ON) ?
            certInfo.download_link ?
              <StyledButton bgColorCode="transparent" borderRadius="20px" minHeight="auto" width="auto" padding="0"
                height="34px" minWidth="130px" border="2px solid var(--textGrey)" onClick={() => onPaidDownload(certInfo.download_link)} >
                <img src='/images/profile/pay-to-print-icon.svg' alt='pay-to-print' />
                <StyledText colorCode='var(--textBlue)' fSize='14px'>
                  <FormattedMessage id="btn-label-dl" defaultMessage="Download" />
                </StyledText>
              </StyledButton>
              :
              ((certInfo.status === paymentGatewayStatusByCertType.PAID) ?
                ((certDownloadInfo.cert_id === certInfo.certificate_id && certDownloadInfo.link) ?
                  <Link to={{ pathname: certDownloadInfo.link || "!#" }} target="_blank">
                    <StyledButton bgColorCode="transparent" borderRadius="20px" minHeight="auto" width="auto" padding="0 3px"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "stretch",
                        height: 48,
                        padding: 12,
                        borderRadius: 10,
                        background: "#6f65e8",
                        width: 'auto'
                      }}
                      height="34px" minWidth="130px" border="2px solid var(--textGrey)">
                      <p
                        style={{
                          flexGrow: 0,
                          flexShrink: 0,
                          fontSize: 14,
                          fontWeight: 600,
                          textAlign: "center",
                          color: "#fff",
                        }}
                      >
                        Print Certificate
                      </p>
                    </StyledButton>
                  </Link>
                  :
                  <StyledButton bgColorCode="transparent" borderRadius="20px" minHeight="auto" width="auto" padding="0 3px"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignSelf: "stretch",
                      height: 48,
                      padding: 12,
                      borderRadius: 10,
                      background: "#6f65e8",
                      width: 'auto'
                    }}
                    height="34px" minWidth="130px" border="2px solid var(--textGrey)" onClick={() => checkoutPrint(certInfo.certificate_id)} >
                    <p
                      style={{
                        flexGrow: 0,
                        flexShrink: 0,
                        fontSize: 14,
                        fontWeight: 600,
                        textAlign: "center",
                        color: "#fff",
                      }}
                    >
                      Print Certificate
                    </p>
                  </StyledButton>
                )
                :
                certInfo.status === paymentGatewayStatusByCertType.FREE ?
                  <StyledButton bgColorCode="transparent" borderRadius="20px" minHeight="auto" width="auto" padding="0 3px"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignSelf: "stretch",
                      height: 48,
                      padding: 12,
                      borderRadius: 10,
                      background: "#6f65e8",
                      width: 'auto'
                    }}
                    height="34px" minWidth="130px" border="2px solid var(--textGrey)" onClick={() => onFreeCheckout(certInfo.certificate_id)} >
                    <p
                      style={{
                        flexGrow: 0,
                        flexShrink: 0,
                        fontSize: 14,
                        fontWeight: 600,
                        textAlign: "center",
                        color: "#fff",
                      }}
                    >
                      Print Certificate
                    </p>
                  </StyledButton>
                  :
                  null
              )
            :
            null
          }
        </div>
      </div>
    </>
  )
}

export default CertItem