import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { useIntl, FormattedMessage } from 'react-intl'
import { Navbar } from '@nextui-org/react'
import { Button, Flex } from '@mantine/core'
import { useTokenAuth } from 'hooks/useTokenAuth'
import useCms from 'contexts/CMS/useCms'
import { initialData } from 'contexts/CMS/Provider'
import { useTranslation } from 'contexts/I18nContext'
import LangSelector from 'components/LangSelectorV2'
import { useDynamicInstInfo } from 'hooks/useDynamicCmsInfo'
import LoginModal from 'views/Login'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Menu: React.FC = () => {
  const query = useQuery();
  const intl = useIntl()
  const { currentLanguage } = useTranslation()
  const [cookie, setCookie, removeCookie] = useCookies(["_acc"])

  const location = useLocation()
  const navigate = useNavigate()
  const { pathname } = location
  const splitLocation = pathname.split("/")
  const { cms, setCms } = useCms()
  const setCmsRef = useRef(setCms)

  const { tokenValidity } = useTokenAuth()
  const { onDynamicInstInfo : handleDynamicInstInfo } = useDynamicInstInfo()

  const [loginOpened, { open: openLogin, close: closeLogin }] = useDisclosure(false)

  // condition to trigger mobile show / hide for login and language selector
  const matchesMobile = useMediaQuery('(max-width: 499px)')

  const [isToggle, setIsToggle] = useState(false)
  const navbarToggleRef = useRef(null)

  const closeMenu = () => {
    navbarToggleRef?.current?.click()
  }
  const logout = () => {
    removeCookie("_acc", {path: '/'})
    navigate('/home')
  }

  useLayoutEffect(() => {
    const getInstInfo = async () => {
      try {
        const response = await handleDynamicInstInfo(window.location.origin, currentLanguage.locale)
        if (response.error) throw new Error(response.message)
        setCmsRef.current(response.data)
      } catch (e: any) {
        setCmsRef.current(initialData)
      }
    }

    getInstInfo()
  }, [handleDynamicInstInfo, currentLanguage.locale])

  useEffect(() => {
    const login = query.get('login')
    if (login && window.location.pathname === '') {
      openLogin()
    }

    if (cms.title) {
      document.title = cms.title
    }
  }, [cms.title, query, openLogin])

  return (
    <>
      <LoginModal opened={loginOpened} onClose={closeLogin} />
      <Navbar maxWidth='fluid' className="m-navbar hidewithSize">
        <Navbar.Brand>
          <a href='#!' className='navbar-brand' style={{ display: 'flex' }}>
            <img src={cms.logo || '/images/MasChainLogo.png?v=2'} alt='logo' style={{ maxWidth: 'clamp(50px, 5vw, 80px)' }} />
          </a>
        </Navbar.Brand>
        <Navbar.Content gap='2rem' hideIn='sm' css={{ marginLeft: '36px', marginRight: 'auto' }}>
          <Navbar.Link key='home' href='/home' className={`${splitLocation[1] === "home" ? "active" : ""}`} >
            <FormattedMessage id="menu-label-home" defaultMessage="Home" />
          </Navbar.Link>
          <Navbar.Link key='verify' href='/verify' className={`${splitLocation[1] === "verify" ? "active" : ""}`} >
            <FormattedMessage id="menu-label-verify" defaultMessage="Verify" />
          </Navbar.Link>
          <Navbar.Link key='masdid' href='https://www.masdid.com/merchant-offers/' >
            <FormattedMessage id="menu-label-masdid" defaultMessage="MasDID" />
          </Navbar.Link>
        </Navbar.Content>
        <Navbar.Content>
          <>
            {!matchesMobile && <Flex gap={16} align='center'>
              {tokenValidity === false ?
                <Button w="auto" h="50px" miw="110px" mt={0} mah="50px" p="5px" onClick={() => openLogin()} ff="var(--fontFamily)"
                  style={{ zIndex: 10, borderRadius: "200px", background: "var(--btnMainColor)" }}
                >
                  <FormattedMessage id='btn-label-login' defaultMessage="Log In" />
                </Button>
                :
                <Link to="/profile">
                  <Button w="auto" h="50px" miw="110px" mt={0} mah="50px" p="5px" onClick={() => console.log()} ff="var(--fontFamily)"
                    style={{ zIndex: 10, borderRadius: "200px", background: "var(--btnMainColor)" }}
                  >
                    <FormattedMessage id='profile-title1' defaultMessage="My Account" />
                  </Button>
                </Link>
              }
              {tokenValidity && <Button w="auto" h="50px" miw="110px" mt={0} mah="50px" p="5px" onClick={() => logout()} ff="var(--fontFamily)"
                style={{ zIndex: 10, borderRadius: "200px", background: "var(--btnMainColor)" }}
              >
                <FormattedMessage id='btn-label-logout' defaultMessage="Log Out" />
              </Button>}
              <LangSelector />
            </Flex>}
            <Flex justify='center' align='center' direction='column' style={{ position: 'relative' }}>
              <Navbar.Toggle showIn='sm' aria-label='toggle navigation' onClick={() => setIsToggle(!isToggle)} ref={navbarToggleRef} />
              <span className={`line middle ${isToggle ? 'hide-line' : ''}`} />
            </Flex>
          </>
        </Navbar.Content>
        <Navbar.Collapse>
          <Navbar.CollapseItem key='home' onClick={() => closeMenu()}>
            <Link to="/home" className={`${splitLocation[1] === "home" ? "active" : ""}`}>
              <FormattedMessage id="menu-label-home" defaultMessage="Home" />
            </Link>
          </Navbar.CollapseItem>
          <Navbar.CollapseItem key='verify-1' onClick={() => closeMenu()}>
            <Link to="/verify" className={`${splitLocation[1] === "verify" ? "active" : ""}`}>
              <FormattedMessage id="menu-label-verify-1" defaultMessage="Verify" />
            </Link>
          </Navbar.CollapseItem>
          <Navbar.CollapseItem key='verify-3' onClick={() => closeMenu()}>
            <a href='https://www.masdid.com/merchant-offers'>MasDID</a>
          </Navbar.CollapseItem>
          {matchesMobile && <>
            <Navbar.CollapseItem key='login'>
              {tokenValidity === false ?
                <Button w="auto" h="50px" miw="110px" mt={0} mah="50px" p="5px" onClick={() => {openLogin(); closeMenu()}} ff="var(--fontFamily)"
                  style={{ zIndex: 10, borderRadius: "200px", background: "var(--btnMainColor)" }}
                >
                  <FormattedMessage id='btn-label-login' defaultMessage="Log In" />
                </Button>
                :
                <Link to="/profile">
                  <Button w="auto" h="50px" miw="110px" mt={0} mah="50px" p="5px" onClick={() => closeMenu()} ff="var(--fontFamily)"
                    style={{ zIndex: 10, borderRadius: "200px", background: "var(--btnMainColor)" }}
                  >
                    <FormattedMessage id='profile-title1' defaultMessage="My Account" />
                  </Button>
                </Link>
              }
            </Navbar.CollapseItem>
            <Navbar.CollapseItem>
              {tokenValidity && <Button w="auto" h="50px" miw="110px" mt={0} mah="50px" p="5px" onClick={() => logout()} ff="var(--fontFamily)"
                style={{ zIndex: 10, borderRadius: "200px", background: "var(--btnMainColor)" }}
              >
                <FormattedMessage id='btn-label-logout' defaultMessage="Log Out" />
              </Button>}
            </Navbar.CollapseItem>
            <Navbar.CollapseItem key='lang'>
              <LangSelector />
            </Navbar.CollapseItem>
          </>}
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}

export default Menu